import { useQuery } from '@apollo/client';
import { GET_DIFFERENT_POSTS } from 'gql/posts';
import {
  FourthTitle,
  SecondaryParagraph,
} from '../StyledComponentsLib/StyledComponentsLib';
import Link from 'next/link';
import PreviewBox from './PreviewBox';
import { WORKS } from 'gql/works';
import styles from './AgentsPreview.module.scss';
import { Category } from 'generated-graphql';
import ResourceItem from '@/components/ResourceItems/ResourceItem';

const AgentsPreview = () => {
  const { data } = useQuery(GET_DIFFERENT_POSTS);
  const { data: workData } = useQuery(WORKS, { variables: { first: 1 } });

  const getCtaLabel = () => {
    const category = data.unboxed?.nodes[0].categories?.nodes.find(
      (c: Category) => c.parent?.node.name === 'Content Type'
    ).name

    // console.log(category, 'category')

    if (category === "Video") {
      return "Watch now";
    }
    if (category === "Webinar") {
      return "Register now";
    }
    return "Read now";

  }

  return (
    <div className="section">
      <div className="container">
        <div className="row gx-5">
          <div className="col-xl-5 mb-5 pe-lg-5">
            <FourthTitle style={{ marginBottom: 20, fontWeight: 600 }}>
              Our latest content
            </FourthTitle>
            <SecondaryParagraph style={{ color: '#000' }}>
              The latest thinking from our specialists across the Agent3 Group
              to help you strengthen relationships with customers, grow your
              business, and accelerate revenue.
            </SecondaryParagraph>
            <div className="mt-5">
              <Link
                className={`${styles.discoverBtn} cta-outline`}
                href="/resources"
              >
                Discover more
              </Link>
            </div>
          </div>
          {data && (
            <div className="col-xl-7">
              <div className="row">
                {data?.agentsOfChange?.nodes.length ? (
                  <div className="col-12 mb-4 col-sm-6  px-3">
                    <ResourceItem item={data.agentsOfChange?.nodes[0]} hideExcerpt />
                  </div>
                ) : null}
                {data.blog.nodes.length ? (

                  <div className="col-12 mb-4 col-sm-6 px-3">
                    <ResourceItem item={data.blog?.nodes[0]} hideExcerpt />
                  </div>
                ) : null}
                {data.unboxed.nodes.length ? (
                  <div className="col-12 mb-4 col-sm-6 px-3">
                    <ResourceItem item={data.unboxed?.nodes[0]} hideExcerpt />
                  </div>
                ) : null}
                {workData?.works?.nodes?.length ? (
                  <div className="col-12 mb-4 col-sm-6 px-3">
                    <ResourceItem item={workData.works?.nodes[0]} hideExcerpt />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AgentsPreview;