import gql from 'graphql-tag';
import { SEOFields, builderFields } from './common/fields';

const builder = builderFields('Post');
export const CORE_POST_FIELDS_KEY = 'CorePostFields';
export const FEATURED_POST_FIELDS_KEY = 'FeaturedPostFields';

export const FEATURED_POST_FIELDS = gql`
fragment ${FEATURED_POST_FIELDS_KEY} on Post {
  id
  title
  slug
  date
  excerpt
  customExcerpt
  categories {
      nodes {
        parent {
          node {
            name
            slug
          }
        }
        name
        id
        slug
      }
    }
    featuredImage {
      node {
        sourceUrl
        altText
        mediaDetails {
          sizes(include: [ITEM_THUMB_X2]) {
            sourceUrl
            
          }
        }
        
      }
    }
    blogSettings {
      externalUrl {
        url
        title
      }
    }
    publishers {
    __typename
    nodes {
        ... on Publisher {
          name
          slug
          people {
            position
            linkedinUrl
            bio
            profilePicture {
              sourceUrl
            }

          }
         
        }
      }
  }
}
`;

export const CORE_POST_FIELDS = gql`
  fragment ${CORE_POST_FIELDS_KEY} on Post {
    id
    title
    excerpt
    customExcerpt
    slug
    date
    databaseId
    ${SEOFields}
    ${builder}
    categories {
      nodes {
        parent {
          node {
            name
            slug
          }
        }
        name
        id
        slug
       
        color {
          bannerImage {
            mediaItemUrl
          }
          bannerBackground
          color
          backgroundColor
          icon {
            sourceUrl
          }
        }
      }
    }

    featuredImage {
      node {
        sourceUrl
        altText
        mediaDetails {
          sizes(include: [ITEM_THUMB_X2]) {
            sourceUrl
          }
        }
      }
    }
    banners {
      nodes {
        banner {
          url
          image {
            mediaItemUrl
          }
        }
      }
    }
    categoryBranding {
      hideBranding
    }
    blogSettings {
      externalUrl {
        url
        title
      }
    }
    tableOfContents {
      items {
        title
        anchor
      }
    }
    publishers {
      __typename
      nodes {
        ... on Publisher {
          name
          slug
          people {
            position
            linkedinUrl
            bio
            profilePicture {
              sourceUrl
            }

          }
      }
    }
  }
}
`;

export const LIST_POST_FIELDS_KEY = 'ListPostFields';
export const LIST_POST_FIELDS = gql`
  fragment ${LIST_POST_FIELDS_KEY} on Post {
    id
    title
    excerpt
    customExcerpt
    slug
    date
    categories {
      nodes {
        parent {
          node {
            name
            slug
          }
        }
        name
        id
        slug
        color {
          bannerImage {
            mediaItemUrl
          }
          bannerBackground
          color
          backgroundColor
          icon {
            sourceUrl
          }
        }
      }
    }
    banners {
        nodes {
          banner {
            url
            image {
              mediaItemUrl
            }
          }
          }
        }

    featuredImage {
      node {
        sourceUrl
        altText
        mediaDetails {
          sizes(include: [ITEM_THUMB_X2]) {
            sourceUrl
          }
        }
      }
    }
    blogSettings {
      externalUrl {
        url
        title
      }
    }
    publishers {
      __typename
      nodes {
          ... on Publisher {
            name
            slug
            people {
              profilePicture {
                mediaDetails {
                  sizes(include: [ITEM_THUMB_X2]) {
                    sourceUrl
                  }
                }
              }
            }
          }
      }
    }
  }
`;

export const POST = gql`
  ${CORE_POST_FIELDS}
  query post($id: ID!) {
    post(id: $id, idType: URI) {
      ...CorePostFields
    }
  }
`;

export const LATEST_100_POSTS = gql`
  query posts {
    posts(first: 1) {
      nodes {
        slug
      }
    }
  }
`;

export const GET_DIFFERENT_POSTS = gql`
  ${LIST_POST_FIELDS}

  query posts {
    blog: posts(where: { categoryName: "blog" }, first: 1) {
      nodes {
        ...ListPostFields
      }
    }

    agentsOfChange: posts(
      where: { categoryName: "agents-of-change" }
      first: 1
    ) {
      nodes {
        ...ListPostFields
      }
    }

    unboxed: posts(where: { categoryName: "unboxed" }, first: 1) {
      nodes {
        ...ListPostFields
      }
    }
  }
`;

export const FEATURED_POSTS = gql`
  ${FEATURED_POST_FIELDS}
  query posts($categoryName: String) {
    posts(where: { categoryName: $categoryName }) {
      nodes {
        ...FeaturedPostFields
      }
    }
  }
`;

export const getPosts = (
  categories: string[] = [],
  field: String = 'DATE',
  sort?: string,
) => {
  const taxArray: any = categories
    .map(
      (c) => `{
    terms: ["${c}"],
    taxonomy: CATEGORY,
    operator: IN,
    field: NAME
  },`,
    )
    .join('');

  return gql`
    ${LIST_POST_FIELDS}
    query posts($first: Int=10, $after: String, $search: String) {
      posts(
        first: $first
        after: $after
        where: { 
          search: $search
          taxQuery: {
            relation: AND,
            taxArray: [
              ${taxArray}
            ]
          },
          orderby: {field: ${field}, order: ${
    field === 'DATE' || field === 'MODIFIED' ? sort || 'DESC' : sort || 'ASC'
  }}
        }
      ) {
        nodes {
          ...ListPostFields
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `;
};
