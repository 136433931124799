import { gql } from '@apollo/client';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import {
  CoreCoverAttributes,
  CoreCover as CoreCoverType,
} from 'generated-graphql';
import { get } from 'lodash';
import LazyLoad from 'react-lazy-load';
import { generateStyle } from './helpers/styles';
// import { AnimationOnScroll } from 'react-animation-on-scroll';

function CoreCover(props: CoreCoverType) {
  const attributes = props.attributes as CoreCoverAttributes;
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const hasNoBorderRadius = attributes.className?.includes("no-br")



  return (
    <div className={`${attributes.className} core-cover d-flex flex-column d-lg-block`}>
      <div className={`cover-img ${hasNoBorderRadius ? "no-br" : ""}`} style={{
        height:
          `${attributes.minHeight}${attributes.minHeightUnit || "px"}` || 'auto', width: "100%", backgroundImage: `url(${attributes.url || '/images/no-image.jpg'})`, backgroundSize: "cover",
      }} />
      <div
        className="cover-overlay d-none d-lg-block"
        style={{
          backgroundColor: attributes.overlayColor
            ? `var(--${attributes.overlayColor})`
            : '#000',
          opacity: attributes.dimRatio ? attributes.dimRatio / 100 : 0,
        }}
      ></div>
      <div
        className={`cover-content ${attributes.contentPosition?.replace(
          ' ',
          '-',
        )}`}
        style={cssObj}
      >
        {props?.innerBlocks ? ( // @ts-ignore
          <WordPressBlocksViewer blocks={props.innerBlocks} />
        ) : null}
      </div>
    </div>
  );
}

CoreCover.fragments = {
  entry: gql`
    fragment CoreCoverFragment on CoreCover {
      attributes {
        url
        contentPosition
        style
        dimRatio
        overlayColor
        minHeight
        minHeightUnit
        className
      }
    }
  `,
  key: `CoreCoverFragment`,
};

CoreCover.displayName = 'CoreCover';

export default CoreCover;
