import React from 'react';
import styles from './GoogleEmbed.module.scss';

const GoogleEmbed = ({ url }: { url: string }) => {
  return (
    <div className='container'>
      <div className={styles.googleEmbed}>
        <iframe src={`${url}&chrome=false`} />
      </div>
    </div>);
};

export default GoogleEmbed;