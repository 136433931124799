import gql from 'graphql-tag';
import { SEOFields, builderFields } from './common/fields';

const builder = builderFields('Work');
export const CORE_WORK_FIELDS_KEY = 'CoreWorkFields';
export const CORE_WORK_FIELDS = gql`
  fragment ${CORE_WORK_FIELDS_KEY} on Work {
    id
    title
    excerpt
    slug
    date
    databaseId
    ${builder}
    ${SEOFields}
    categories {
      nodes {
        parent {
          node {
            name
            slug
          }
        }
        name
        id
        slug
        color {
          color
          backgroundColor
          icon {
            sourceUrl
          }
        }
      }
    }
    works {
      client
    }

    featuredImage {
      node {
        sourceUrl
      }
    }
    featuredVideo {
      file {
        sourceUrl
        id
      }
      videoUrl
    }
  }

`;

export const LIST_WORK_FIELDS_KEY = 'ListWorkFields';
export const LIST_WORK_FIELDS = gql`
  fragment ${LIST_WORK_FIELDS_KEY} on Work {
    id
    title
    excerpt
    slug
    date

    categories {
      nodes {
        parent {
          node {
            name
            slug
          }
        }
        name
        id
        slug
        color {
          color
          backgroundColor
          icon {
            sourceUrl
          }
        }
      }
    }
    works {
      client
    }

    featuredImage {
      node {
        sourceUrl
        mediaDetails {
          sizes(include: [ITEM_THUMB_X2]) {
            sourceUrl
          }
        }
      }
    }
    featuredVideo {
      file {
        sourceUrl
        id
      }
      videoUrl
    }
  }

`;

export const WORK = gql`
  ${CORE_WORK_FIELDS}
  query work($id: ID!) {
    work(id: $id, idType: URI) {
      ...CoreWorkFields
    }
  }
`;

export const getWorks = (categories: string[] = [], field: String = 'DATE') => {
  const taxArray: any = categories
    .map(
      (c) => `{
    terms: ["${c}"],
    taxonomy: CATEGORY,
    operator: IN,
    field: NAME
  },`,
    )
    .join('');

  return gql`
    ${LIST_WORK_FIELDS}
    query works($first: Int=100, $after: String, $search: String) {
      works(
        first: $first
        after: $after
        where: { 
          search: $search
          taxQuery: {
            relation: AND,
            taxArray: [
              ${taxArray}
            ]
          },
          orderby: {field: ${field}, order: ${
    field === 'DATE' ? 'DESC' : 'ASC'
  }}
        }
      ) {
        nodes {
          ...ListWorkFields
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `;
};

export const FEATURED_WORKS = gql`
  query featuredWorks($categoryName: String, $first: Int, $after: String) {
    works(
      first: $first
      after: $after
      where: { categoryName: $categoryName }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              sizes(include: [ITEM_THUMB_X2]) {
                sourceUrl
              }
            }
          }
        }
        works {
          client
        }
      }
    }
  }
`;

export const WORKS = gql`
  ${CORE_WORK_FIELDS}
  query works(
    $categoryIn: [ID]
    $first: Int = 100
    $after: String
    $notIn: [ID]
  ) {
    works(
      first: $first
      after: $after
      where: { categoryIn: $categoryIn, notIn: $notIn }
    ) {
      nodes {
        ...CoreWorkFields
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const WORKS_SLUGS = gql`
  query worksSlugs {
    works(first: 100) {
      nodes {
        title
        slug
      }
    }
  }
`;
