import { gql } from '@apollo/client';
import {
  CoreImageAttributes,
  CoreImage as CoreImageType,
} from 'generated-graphql';
import LazyLoad from 'react-lazy-load';
import { generateStyle } from './helpers/styles';
import get from 'lodash/get';

function CoreImage(props: CoreImageType) {
  const attributes = props.attributes as CoreImageAttributes;

  if (attributes.className?.includes('raw')) {
    return (
      <div dangerouslySetInnerHTML={{ __html: props?.renderedHtml || '' }} />
    );
  }
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const keyword = 'order';
  const regex = new RegExp(`\\b${keyword}\\S*`, 'gi');

  const orderClasses = attributes.className?.match(regex)?.join(' ') || '';
  const otherClasses = attributes.className?.replace(regex, '').trim() || '';

  const img = (
    <img
      style={{
        width: attributes.width || 'auto',
        height: attributes.height || 'auto',
        ...cssObj,
      }}
      className={`img-fluid ${otherClasses}`}
      src={attributes?.src || '/images/no-image.jpg'}
      alt={attributes?.alt || 'image'}
      width={attributes.width || 'auto'}
      height={attributes.height || 'auto'}
    />
  );
  return <div className={`core-image d-flex ${orderClasses}`}>
    {attributes.href ?
      <a href={attributes.href} target="_blank" rel="noreferrer">
        {img}</a> :
      img}
  </div>;
}

CoreImage.fragments = {
  entry: gql`
    fragment CoreImageFragment on CoreImage {
      renderedHtml
      attributes {
        href
        src
        alt
        height
        width
        className
        style
      }
    }
  `,
  key: `CoreImageFragment`,
};

CoreImage.displayName = 'CoreImage';

export default CoreImage;