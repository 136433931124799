import Slider from 'react-slick';
// import ReactPlayer from 'react-player';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import {
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Img,
  ThirdParagraph,
} from '../StyledComponentsLib/StyledComponentsLib';
import styled from 'styled-components';
import { useIsomorphicLayoutEffect } from '../ScrollPlayback/ScrollPlayback';
import { Page_Builder_Sections_Featured } from 'generated-graphql';
import dynamic from 'next/dynamic'
import Link from 'next/link';
import LinkIcon from '/public/svgs/link-icon.svg';
import styles from './Featured.module.scss';
import LazyLoad from 'react-lazy-load';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const SliderWrapper = styled.div`
  .slick-slide {
    min-height: fit-content;
  }

  .slick-list {
    position: inherit;
  }
 
  .slick-dots {
  position: relative;
  padding-left: 0;
  margin-top: 0;
  flex-wrap: wrap;
  flex-direction: row;

  @media all and (max-width: 768px) {
    display: flex !important; 
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    width: auto;
    ::-webkit-scrollbar {
      display: none;
    }
      -ms-overflow-style: none;  
      scrollbar-width: none; 
  }
 
  li {
    width: auto;
    height: auto;
    margin-right: 30px;
    
    
   
    p {
      padding: 10px 14px;
      color: #0d2a31;
      border-radius: 5px;
      border: 2px solid transparent;
      width: max-content;
      &:hover {
        color: #55389E;

      }
    }
  }

  li[class*='slick-active'] {  
    p {
      background-color: #E6E1FD;
      font-weight: 900;
      color: #55389E;
    }
  }
}
`;
gsap.registerPlugin(ScrollTrigger);

const Featured: React.FC<Page_Builder_Sections_Featured> = (props) => {
  const { videos, text } = props;

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState<number | null>(0);
  const [videosStates, setVideosStates] = useState<boolean[] | undefined>(
    videos?.map(() => false),
  );

  const settings = {

    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,

    beforeChange: () => {
      setVideosStates((videosState) =>
        videosState?.map((state, i) => (i === currentSlide ? false : state)),
      );
      setCurrentSlide(null);
    },
    afterChange: (currentIndex: number) => {
      setCurrentSlide(currentIndex);
      setVideosStates((videosState) =>
        videosState?.map((state, i) => (i === currentIndex ? true : state)),
      );
    },


    customPaging: (i: number) => (
      <ThirdParagraph>{videos?.[i]?.name}</ThirdParagraph>
    ),
  };
  useIsomorphicLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (sliderRef.current) {
        ScrollTrigger.create({
          trigger: sliderRef.current,
          start: 'top center',
          end: 'bottom top',
          onToggle: (self) =>
            setVideosStates((videosState: boolean[] | undefined) =>
              videosState?.map((state: boolean, i: number) =>
                i === currentSlide ? self.isActive : state,
              ),
            ),
        });
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="section">
      <div className="container mb-5">

        <SliderWrapper ref={sliderRef} className="row">

          {text && <div style={{ marginBottom: 50 }} dangerouslySetInnerHTML={{ __html: text }} />}
          <LazyLoad offset={600}>
            <Slider {...settings}>
              {videos?.map((node: any, index: number) => (
                <div key={index} className="col-12 text-center">
                  <div style={{ position: 'relative', paddingTop: '56.25%', borderRadius: "16px", overflow: "hidden" }}>
                    <ReactPlayer
                      url={node.videoUrl}
                      playing={videosStates?.[index]}
                      style={{ position: 'absolute', top: 0, left: 0 }}
                      width="100%"
                      height="100%"
                      muted
                    />
                    <Link className={styles.link} key={index} href={node.caseStudyUrl || ""} style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%" }} >
                      <LinkIcon />
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </LazyLoad>
        </SliderWrapper>

      </div>
    </div>
  );
  // return <div>{JSON.stringify(data?.works?.nodes)}</div>;
};
export default Featured;
