import styles from './A3Player.module.scss';
import CopyIcon from '/public/svgs/copy.svg';
import LinkedInIcon from '/public/svgs/linkedin-icon.svg';
import { Post } from 'generated-graphql';
import { copyToClipboard } from "@/helpers/copy";
import { LinkedinShareButton } from 'react-share';

export interface VideoDetailsProps {
  videoItem: Post
}

const VideoDetails: React.FC<VideoDetailsProps> = ({ videoItem }) => {
  const { title, excerpt, customExcerpt } = videoItem;
  const shareUrl = typeof window !== 'undefined' ? window?.location?.href : '';

  return (
    <>
      <div className={styles.description}>
        <div className='d-flex justify-content-between flex-xl-row flex-column align-items-xl-center py-4'>
          <h5 className='mb-3 me-xl-3 mb-xl-0'>{title}</h5>
          <div className={styles.linksWrapper}>
            <button className="copyBtn me-3" style={{ color: "#000", height: "fit-content" }} onClick={copyToClipboard}>
              <CopyIcon className={styles.copyIcon} /> Copy Link
            </button>

            <LinkedinShareButton style={{ height: "fit-content" }} url={shareUrl}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  border: '1px solid #0C2A31',
                  borderRadius: '50%',
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <LinkedInIcon className={styles.linkedin} />
              </div>
            </LinkedinShareButton>
          </div>
        </div>
        {(excerpt || customExcerpt) && <div className={styles.videoDescription} dangerouslySetInnerHTML={{ __html: excerpt || customExcerpt || '' }} />
        }
      </div>
    </>
  )
}

export default VideoDetails;