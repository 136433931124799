import Link from 'next/link';
import styles from './Footer.module.scss';
import { MenuItem } from 'generated-graphql';
import { useRouter } from 'next/router';
import Image from 'next/image';


declare global {
  interface Window { Cookiebot: any; }
}

// window.Cookiebot = window.Cookiebot || {};

interface FooterProps {
  menuItems: any[];
  isThisMachine?: boolean;
}

const Footer: React.FC<FooterProps> = ({ menuItems = [], isThisMachine }) => {
  const documents = menuItems?.find((item) => item.label === '[documents]');
  const router = useRouter()


  return (
    <footer className={`${isThisMachine ? styles.tmFooter : ""} ${styles.footer} `}>
      <div className="container">
        <div className={styles.footerWrapper}>
          <div className={styles.menuItemsWrapper}>
            <div className="col-lg-4 col-12">
              <Image src={isThisMachine ? "/svgs/this-machine-dark.svg" : "/svgs/A3G-logos-light.svg"} alt={isThisMachine ? "ThisMachine Logo" : "Agent3Group Logo"} width={203} height={44} quality={100} />

            </div>
            <div className="col-lg-8 col-12 d-flex justify-content-lg-end justify-content-between flex-wrap">
              {menuItems?.map((item, idx) => {
                const isContact = item.label === 'Contact us';
                return (
                  item.label !== '[documents]' && (
                    <div
                      className={`${styles.menuItem} ${isContact && styles.contactBtn
                        } order-${isContact ? 0 : idx + 1} order-lg-${idx}`}
                      key={item.id}
                    >
                      {!isContact ? (
                        <div>
                          <p>{item.label}</p>{' '}
                          <ul>
                            {item?.childItems?.nodes?.map(
                              (submenu: MenuItem) => (
                                <li key={submenu.id}>
                                  <Link href={submenu.url || ''}>
                                    {submenu.label}
                                  </Link>
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      ) : (
                        <Link className='cta' href={item.url || '#'}>{item.label}</Link>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div
            className={`d-flex justify-content-md-between ${styles.documents}`}
          >
            <p className="order-2 order-md-1">
              © {new Date().getFullYear()}. Agent3 & Agent3 Group. All rights reserved.
            </p>
            <div className="order-1 order-md-2">
              {documents?.childItems?.nodes?.map((doc: MenuItem) => {
                if (doc.label === "Cookies") {
                  return <a key={doc.id} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (typeof window !== "undefined" && window.Cookiebot && window.Cookiebot.hasOwnProperty('show')) {
                      window.Cookiebot.show();
                    }

                  }}>Cookies</a>
                }
                return <Link key={doc.id} href={doc.url || ''}>
                  {doc.label}
                </Link>
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
