import styled from 'styled-components';

interface TitleProps {
  readonly isActive?: boolean;
}
interface TextProps {
  readonly arrow?: boolean;
  readonly TypeWight?: string;
}

interface TypeTextProps {
  readonly TypeText?: boolean;
}

interface CategoryProps {
  readonly color: string;
  readonly bgColor: string;
}

interface TruncatedProps {
  lines: number;
  minHeight?: string;
}

interface ImageProps {
  readonly TypeImgRound?: boolean;
  readonly circleImg?: boolean;

}

export const PrimaryTitle = styled.h1<TextProps>`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.main};
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};
  @media all and (min-width: 1440px) {
    font-size: 72px;
    line-height: 90px;
  }
`;
export const SecondaryTitle = styled.h2<TextProps>`
  color: ${(props) => props.theme.colors.main};
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.02em;
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};
`;
export const ThirdTitle = styled.h3<TextProps>`
  color: ${(props) => props.theme.colors.tags};
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};
`;
export const FourthTitle = styled.h4<TextProps>`
  color: ${(props) => props.theme.colors.tags};
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.02em;
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};

  @media all and (min-width: 992px) {
    font-size: 36px;
    line-height: 44px;
  }
`;
export const FifthTitle = styled.h5<TextProps>`
  color: ${(props) => props.theme.colors.tags};
  font-size: 29px;
  line-height: 36px;
  letter-spacing: -0.02em;

  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};
`;
export const SixthTitle = styled.h6<TextProps>`
  color: ${(props) => props.theme.colors.tags}; 
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  display:contents;
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};
`;

export const MainParagraph = styled.p`
  color: ${(props) => props.theme.colors.copy};
  font-size: 12px; 
  line-height: 18px; 
  font-weight: 400;

  @media all and (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media all and (min-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media all and (min-width: 1200px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media all and (min-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const SecondaryParagraph = styled.p<TypeTextProps>`
  color: ${(props) => props.theme.colors.copy};
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  ${(props) => (props.TypeText ? props.theme.type2 : '')};
`;

export const ThirdParagraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400; 
  
`;
export const FourthParagraph = styled.p<TextProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  ${(props) => props.theme.weights[props.TypeWight ?? 'default']};

`;
export const FifthParagraph = styled.p`
  font-size: 12px;
  line-height: 18px; 
  font-weight: 400;
`;

export const SidebarNames = styled.p` 
  color: ${(props) => props.theme.colors.copy}; 
  cursor: pointer;
  font-size: 16px;
  line-height: 24px; 
  font-weight: 400;
  padding: 10px 14px 10px 14px; 
  border-radius: 6px;
  &:hover {
    ${(props) => props.theme.hoverSidebarName}
  }
`;

export const Excerpt = styled.div`
  * {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #667085 !important;
  }
`;

export const Ol = styled.ol`
  color: ${(props) => props.theme.colors.copy};
  padding-left: 1rem;
`;
export const Button = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 12px 20px;
  background: #000000;
  border-radius: 16px;
  outline: none;
  border: none;
`;
export const Input = styled.input`
  height: 44px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #98a2b3;
  padding: 10px 14px 10px 14px;
  outline: none;
  background-color: white;
`;
export const IconWrapper = styled.span`
  display: inline-block;
  border: 1px solid #98a2b3;
  padding: 10px;
  margin-right: 5px;
  border-radius: 15px;
  cursor: pointer;
`;
export const Categories = styled.span<CategoryProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 0 10px 2px;
  margin: 5px 5px 5px 0;
  border-radius: 16px;
  display: inline-block;
`;

export const Img = styled.img<ImageProps>`
  max-width: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  ${(props) => (props.TypeImgRound ? props.theme.TypeImgRound : '')};
  ${(props) => (props.circleImg ? props.theme.circleImg : '')};
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};

`;

export const Truncated = styled.span<TruncatedProps>`
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: ${(props) => props.lines};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  min-height: ${(props) => props.minHeight || 'auto'};
`;
export const Author = styled.div`
display: flex;
align-items: center;
img {
  width: 40px;
  border-radius: 50% !important;
}
p{
  margin: 0 15px;
  &:nth-child(1) {
    color: #344054;
  }
  &:nth-child(2) {
    color: #667085;
  }
}
`;
export const TimeRead = styled.div`
background-color: #DADADC;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    max-width: fit-content;
    margin-bottom: 20px;
    p{
      display: inline-block;
      margin:0;
      &:nth-child(1) {
        background-color: #fff;
        padding: 2px 8px;
        border-radius: 16px; 
      }
      &:nth-child(2) {
        padding: 2px 8px;
        border-radius: 16px;
      }
    }

`;
