import Navbar from '@/components/Navbar/Navbar';
import Footer from '@/components/Footer/Footer';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import parse from 'html-react-parser';
import { TransitionProvider } from '@/context/TransitionContext';
import TransitionComponent from '@/components/Transition/Transition';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useAppContext } from 'lib/context';
import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import Loader from '@/components/Loader/Loader';
import { Manrope, Baloo_2 } from 'next/font/google';
import localFont from 'next/font/local';

const manrope = Manrope({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
});


const iconsFont = localFont({
  src: [
    {
      path: '../../public/fonts/icons.woff',
    },
  ],
});
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
export default function MainLayout({
  menus,
  // pageData,
  pageBlocks,
  children,
  loading
}: {
  menus: { main: any; footer: any };
  // pageData?: any;
  pageBlocks?: any;
  children?: ReactNode;
  loading?: boolean;
}) {
  const { pageType, setPageType } = useAppContext();


  const router = useRouter()
  const currentPage = router.asPath.substring(1);



  useEffect(() => {
    if (pageBlocks?.__typename && pageBlocks.databaseId) {
      setPageType(pageBlocks.__typename)
      document.body.className = `${`${pageBlocks.__typename.toLowerCase()}-${pageBlocks.databaseId}`}`;
    } else {
      setPageType(currentPage)
      document.body.className = `${currentPage}`;
    }
  }, [pageBlocks?.__typename, currentPage])

  const fullHead = parse(pageBlocks?.seo?.fullHead.replaceAll("Tihomir Todorov", pageBlocks?.publishers?.nodes[0]?.name) || '');

  gsap.registerPlugin(ScrollTrigger);

  return (
    <>
      <Head>
        {fullHead}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />


        {/* <script src="https://unpkg.com/aos@2.3.1/dist/aos.js" defer></script>
        <link
          href="https://unpkg.com/aos@2.3.1/dist/aos.css"
          rel="stylesheet"
        /> */}
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link
          rel="preload"
          href="/svgs/A3G-logos.svg"
          as="image"
          type="image/svg"
        />
      </Head>
      {/* <Script
        id="aos-init"
        dangerouslySetInnerHTML={{ __html: `AOS.init()` }}
      /> */}

      {/* <Loader loading={loading} /> */}
      <div className={manrope.className}>
        <style jsx global>{`
                :root {
                  --root-font: ${manrope.style.fontFamily};
                  --icons-font: ${iconsFont.style.fontFamily};
                 
                }
              `}</style>
        {menus?.main && <Navbar menuItems={menus?.main} />}

        {/* <nav>
        <ul>
          <li>
            <Link href="/">Boxes</Link>
          </li>
          <li>
            <Link href="/about">ScrollTrigger</Link>
          </li>
        </ul>
      </nav> */}

        {/* <main>{children}</main> */}

        <TransitionProvider>

          <TransitionComponent><div className="content-container">{children}</div></TransitionComponent>

        </TransitionProvider>
        {/* <div className="content-container">{children}</div> */}

        {menus?.footer && <Footer menuItems={menus?.footer} />}
      </div>

    </>
  );
}
