import { Post } from "generated-graphql";
import ResourceListItem from "./ResourceListItem";
import { A3ResourceProps } from "@/componentsWP/A3Player/A3Player";

const ResourceList: React.FC<A3ResourceProps> = ({ items, description }) => {
  const getColumnClass = (idx: number) => {
    switch (idx) {
      case 0:
        return "col-lg-12";
      case 1:
      case 2:
        return "col-lg-6";
      default:
        return "col-lg-4";
    }
  };
  if (!items) {
    return null;
  }

  return (

    <div className="container">
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />
      }
      <div className="row gx-5">
        {items.map((item, idx) => {

          return <div style={{ marginTop: 45, marginBottom: 45 }} key={"resourcelistItem" + idx} className={getColumnClass(items.length === 2 ? 0 : idx)}>
            <ResourceListItem item={item as Post} isFirst={idx === 0 || items.length === 2} />
          </div>
        })}

      </div>
    </div>
  )

}

export default ResourceList;