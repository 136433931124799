import styles from './ThisMachineFooter.module.scss'
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });
const DynamicForm = dynamic(() => import('@/componentsWP/HSForm/HSForm'), {
  loading: () => <p>Loading form...</p>,
});

const ThisMachineFooter = () => {
  return (
    <div className={styles.footer}>


      <div className={styles.overlay}></div>
      <ReactPlayer
        className={styles.playerTm}
        url='/videos/tm-footer.mp4'
        playing={true}
        muted
        controls={false}
        width="100%"
        height="100%"
        loop
        playsinline={true}

      />
      <div id="contact" className={styles.text}>
        <div className='container h-100 position-relative'>

          <div className={styles.content}>
            <h2 className='px-4'>
              Are you ready <br className='br' /> to sh<img src='/images/tm-logo.gif' />w up?
            </h2>
            <DynamicForm formId='18a36c52-26f5-4e85-ace6-8708bc9dfee7' />
            <div className={styles.logos}>
              <p>
                <span className="me-3" >Agent3 Group LLC</span>
                <a className="me-3" href="https://www.agent3.com/tcs-usa" target="_blank" rel="noopener noreferrer">T&Cs USA</a>
                <a className="me-3" href="https://www.agent3.com/tcs-uk" target="_blank" rel="noopener noreferrer">T&Cs UK</a>
                <a className="me-3" href="https://www.agent3.com/ccpa" target='_blank' rel="noopener noreferrer">CCPA</a>
              </p>
              <img src='/svgs/this-machine.svg' />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default ThisMachineFooter