import 'src/styles/globals.scss';
import '@/styles/bootstrap/app-bootstrap.scss';
import Router, { useRouter } from 'next/router';
import { setCookie } from 'cookies-next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { initializeApollo, useApollo } from 'lib/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { FaustProvider } from '@faustwp/core';
import '../../faust.config.js';

// @ts-ignore
import { WordPressBlocksProvider } from '@faustwp/blocks';
import blocks from '@/wp-blocks';
import { useEffect, useLayoutEffect, useState } from 'react';
import { UTMParam, UTMsType } from '@/helpers/utms';

import { Manrope } from 'next/font/google';
import localFont from 'next/font/local';
import MainLayout from '../layouts/Main';
import { getGlobalData } from 'lib/global.js';
import { Theme } from '../providers/StyledComponentesProvider';

import gsap from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import Script from 'next/script.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContextProvider } from 'lib/context';
import ThisMachineLayout from '@/layouts/ThisMachine';
import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import { GetStaticPropsContext, NextPageContext } from 'next';
import App from 'next/app';
import HalogenLayout from '@/layouts/Halogen';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// import MainLayout from '../layouts/Main.jsx';

declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchPriority?: 'high' | 'low' | 'auto';
  }
}

export const UTMS_COOKIE = 'utms';

type AppOwnProps = { host: string };

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppProps & AppOwnProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const apolloClient = useApollo(pageProps);

  // console.log('pageProps', pageProps.host);

  const currentUrl = router.asPath;
  // console.log(currentUrl, "")

  const utms = {} as UTMsType;
  let hasUTMs = false;
  if (router.query) {
    Object.keys(router.query).map((p: string) => {
      if (Object.values(UTMParam).includes(p as UTMParam)) {
        hasUTMs = true;
        utms[p as UTMParam] = String(router.query[p]);
      }
    });
  }
  if (Object.keys(utms).length) {
    setCookie(UTMS_COOKIE, JSON.stringify(utms));
  }

  useEffect(() => {
    if (document) {
      const hashLinks = document.querySelectorAll(
        `.pin-spacer-Banner a[href*="#"]`,
      );
      // console.log('hashLinks', hashLinks);
      hashLinks.forEach((hl) => {
        hl.addEventListener('click', (e: Event) => {
          // ScrollTrigger.refresh();
          const aHref = hl.getAttribute('href');

          if (aHref && aHref[0] === '#') {
            e.preventDefault();
            e.stopPropagation();
            if (window) {
              window.location.hash = aHref;
            }
            const scrollToEl = document.querySelector(aHref);
            if (scrollToEl) {
              const elementPosition = scrollToEl.getBoundingClientRect().top;
              const bannerEl = document.querySelector(
                '.pin-spacer-Banner > section',
              ) as HTMLElement;
              const bannerHeight = bannerEl.getBoundingClientRect().height;
              const firstEl = document.querySelector(
                '.firstWrapper',
              ) as HTMLElement;
              const firstElHeight = firstEl.getBoundingClientRect().height;

              const wH = 0; //window.innerHeight / 2; //elementPosition > window.innerHeight + bannerHeight ? window.innerHeight / 2 : window.innerHeight;
              // alert('wH:' + wH);
              // if (elementPosition)
              const offsetPosition =
                elementPosition + window.pageYOffset - 70 - firstElHeight;
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          }
        });
      });
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    const hash = currentUrl.split('#')[1];
    if (hash) {
      setTimeout(() => {
        const scrollToEl = document.querySelector(`#${hash}`);
        // const hasBanner = document.querySelector('body.');
        if (scrollToEl) {
          var elementPosition = scrollToEl.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - 70;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 1500);
    }
  }, [currentUrl]);

  useEffect(() => {
    Router.events.on('hashChangeStart', (url) => {
      // console.log('hashChangeStart', url);
    });

    Router.events.on('routeChangeStart', (url) => {
      setIsLoading(true);
      // console.log('url', url);
    });

    Router.events.on('routeChangeComplete', (url) => {
      setFinished(true);

      const w: any = window;
      const _hsq = (w._hsq = w._hsq || []);
      _hsq.push(['setPath', url]);
      _hsq.push(['trackPageView']);
      // console.log('hsq push ', url);

      ScrollTrigger.refresh();
      const interval = setInterval(() => {
        setIsLoading(false);
        setFinished(false);
        clearInterval(interval);
      }, 500);

      // setTimeout(() => {
      //   const aosGo = (window as any).AOS;
      //   if (aosGo) {
      //     aosGo.init();
      //   }
      // }, 500);
    });

    Router.events.on('routeChangeError', (url) => {
      setIsLoading(false);
      ScrollTrigger.refresh();
    });
  }, []);

  let layoutAndContent = (
    <MainLayout
      pageBlocks={pageProps.pageBlocks}
      menus={pageProps.menus}
      loading={isLoading}
    >
      <ToastContainer
        toastStyle={{ backgroundColor: '#007C34' }}
        closeOnClick
        position="bottom-center"
      />
      <Component {...pageProps} key={router.asPath} />
    </MainLayout>
  );

  const isTM = pageProps.pageBlocks?.title?.includes(' | This Machine');
  const isTMHost =
    pageProps.host &&
    (pageProps.host.indexOf('stg.thismachine.agency') === 0 ||
      pageProps.host.indexOf('thismachine.me') === 0 ||
      pageProps.host.indexOf('thismachine.agency') === 0);

  // if (
  //   isTM ||
  //   currentUrl.indexOf('/this-machine-2') === 0 ||
  //   pageProps.host.indexOf('stg.thismachine.agency') === 0 ||

  if (isTM || isTMHost || currentUrl.indexOf('/this-machine-2') > 0) {
    layoutAndContent = (
      <ThisMachineLayout
        menus={pageProps.menus}
        pageBlocks={pageProps.pageBlocks}
      >
        <ToastContainer
          toastStyle={{ backgroundColor: '#007C34' }}
          closeOnClick
          position="bottom-center"
        />
        <Component {...pageProps} key={router.asPath} />
      </ThisMachineLayout>
    );
  }

  const isHalo = pageProps.layout === 'halogen';
  const isHaloHost =
    pageProps.host &&
    (pageProps.host.indexOf('halogendemand.com') === 0 ||
      pageProps.host.indexOf('www.halogendemand.com') === 0);

  if (isHalo || isHaloHost) {
    layoutAndContent = (
      <HalogenLayout>
        <Component {...pageProps} key={router.asPath} />
      </HalogenLayout>
    );
  }

  return (
    <Theme>
      <FaustProvider pageProps={pageProps}>
        {/* @ts-ignore */}
        <WordPressBlocksProvider config={{ blocks }}>
          <ApolloProvider client={apolloClient}>
            <AppContextProvider>
              {/* {pageProps.pageBlocks.title} */}
              {layoutAndContent}
            </AppContextProvider>
          </ApolloProvider>
        </WordPressBlocksProvider>
      </FaustProvider>
    </Theme>
  );
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const apolloClient = initializeApollo();

  // const host =
  // const res = await fetch('https://api.github.com/repos/vercel/next.js');
  // const json = await res.json();
  // return { stars: json.stargazers_count };
  return await getGlobalData({}, apolloClient);
}
