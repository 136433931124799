import { gql } from '@apollo/client';
import React from 'react';
// @ts-ignore
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { CoreButtons as CoreButtonsType } from 'generated-graphql';
import { generateStyle } from './helpers/styles';
import get from 'lodash/get';

function CoreButtons(props: CoreButtonsType) {
  const attributes = props.attributes;
  const justify =
    attributes?.layout && JSON.parse(attributes?.layout).justifyContent;

  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const blocksViewer = props?.innerBlocks ? ( // @ts-ignore
    <WordPressBlocksViewer blocks={props.innerBlocks} />
  ) : null;
  return (
    <div
      className={`core-buttons ${props.attributes?.className}`}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: justify || 'flex-start',
        ...cssObj,
      }}
    >
      {blocksViewer}
    </div>
  );
}

CoreButtons.fragments = {
  entry: gql`
    fragment CoreButtonsFragment on CoreButtons {
      cssClassNames
      attributes {
        layout
        className
        style
      }
    }
  `,
  key: `CoreButtonsFragment`,
};

CoreButtons.displayName = 'CoreButtons';

export default CoreButtons;
