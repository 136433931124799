import { gql } from '@apollo/client';

export const FOOTER_FRAGMENT = {
  entry: gql`
    fragment FooterFragment on RootQuery {
      footerMenuItems: menu(id: "Footer", idType: NAME) {
        count
        id
        name
        slug
        menuItems(where: { parentDatabaseId: 0 }) {
          nodes {
            id
            title
            url
            cssClasses
            label
            target
            parentId
            childItems {
              nodes {
                id
                title
                url
                cssClasses
                label
                target
                parentId
                childItems {
                  nodes {
                    id
                    title
                    url
                    cssClasses
                    label
                    target
                    parentId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  key: 'FooterFragment',
};
