import { gql } from "@apollo/client";
import { CoreHeading as CoreHeadingType } from "generated-graphql";
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';
import DownArrow from 'public/svgs/down-arrow.svg';
import RightArrow from 'public/svgs/right-arrow.svg';
import UpArrow from 'public/svgs/up-arrow.svg';

function CoreHeading(props: CoreHeadingType) {
  const attributes = props.attributes;
  const textAlign = get(attributes, 'align', 'left');

  const classes = get(attributes, 'className') || '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const hasArrow = classes.includes("arrow")
  const iconColor = attributes?.textColor ? `var(--${attributes?.textColor})` : "#000";
  const iconStyle = {
    fill: iconColor,
    stroke: iconColor,
    width: 16,
    height: 16
  }

  return (
    <div className={`d-flex core-heading ${hasArrow && "heading-arrow"} ${classes}`}>
      <div
        style={{
          width: hasArrow ? "90%" : "100%",
          //@ts-ignore
          textAlign,
          ...cssObj
        }}
        dangerouslySetInnerHTML={{ __html: props.renderedHtml || "" }}

      />
      {classes.includes('arrow-up') && <UpArrow style={iconStyle} />}
      {classes.includes('arrow-down') && <DownArrow style={iconStyle} />}
      {classes.includes('arrow-right') && <RightArrow style={iconStyle} />}
    </div>
  );
}

CoreHeading.fragments = {
  entry: gql`
    fragment CoreHeadingFragment on CoreHeading {
      renderedHtml
      cssClassNames
      attributes {
        content
        level
        textColor
        align
        className
      }
    }
  `,
  key: `CoreHeadingFragment`,
};

CoreHeading.displayName = "CoreHeading";

export default CoreHeading;
