import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import { Capability, Page_Builder_Sections_Results } from 'generated-graphql';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import styles from './Results.module.scss';
gsap.registerPlugin(ScrollTrigger);

const Results: React.FC<Page_Builder_Sections_Results> = (props) => {
  const { copy, stat, capabilities } = props;

  const getSeparateValues = (string: string) => {
    const pattern = /^(\D*)(\d+)(.*)/;

    const match = string.match(pattern);
    let prefix;
    let number;
    let suffix;
    if (match) {
      prefix = match[1] || '';
      number = match[2];
      suffix = match[3] || '';
    }
    return { prefix, number, suffix };
  };

  const filteredStats = stat?.filter((item) => item?.value)

  useIsomorphicLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (filteredStats?.length) {
        const wrapIndex = gsap.utils.wrap(0, filteredStats.length);
        gsap.to('.countTo', {
          textContent: (i: number) => {
            const result = getSeparateValues(filteredStats[wrapIndex(i)]?.value || '');
            return result.number || "";
          },
          snap: { textContent: 1 },
          stagger: 0.2,
          duration: 2,
          scrollTrigger: {
            trigger: '.countTo',
            start: 'center bottom',
          },
        });
      }
    });

    return () => ctx.revert();
  }, []);
  return (
    <div className='section'>
      <div className={styles.results}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pe-lg-5">
              <div
                className={styles.copy}
                dangerouslySetInnerHTML={{ __html: copy || '' }}
              />
            </div>
            <div className="col-lg-6 ps-lg-5 mt-4 mt-lg-0">
              <div className="row gx-5">
                {stat?.map((s, idx: number) => {
                  const result = getSeparateValues(s?.value || '');
                  return (
                    <div key={idx} className="col-6 mb-5">
                      {s?.value && <div className={styles.value}>
                        {result.prefix && <span>{result.prefix}</span>}

                        <span className="countTo">0</span>
                        {result.suffix && <span>{result.suffix}</span>}
                      </div>}
                      {s?.wordValue && <div className={styles.value}>
                        <span>{s.wordValue}</span>
                      </div>}

                      <div className={styles.description}>{s?.text}</div>
                    </div>
                  );
                })}
              </div>
              {capabilities?.length && (
                <div className="d-flex">
                  {capabilities.map((c, idx: number) => (
                    <span
                      className={`me-lg-3 px-3 py-1 ${styles.capability}`}
                      key={idx + 'capability'}
                    >
                      {c?.title}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Results;
