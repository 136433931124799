
import styles from './Navbar.module.scss'
import Button from '../Button/Button';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { halogenURL } from '@/helpers/halogen';
interface NavMenu {
  link?: string;
  label?: string;
}
interface NavbarProps {
  navMenu?: NavMenu[]
}
const Navbar: React.FC<NavbarProps> = ({ navMenu }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpened, setMobileOpened] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      setIsScrolled(scrollTop > 0);
      setMobileOpened(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className={`position-relative ${isScrolled ? styles.hideMenu : ''}`}>
      <div className={`container  ${styles.navbar}`}>
        <div className='row px-3'>
          <div className={`col-12 col-lg-9 d-lg-flex align-items-center position-relative  ${styles.menu}`}>
            <Link href={halogenURL('/')} className={styles.logoWrapper}>
              <img className='no-br' src="/halogenImg/halogenLogo.png" alt="" />
            </Link>
            <div
              onClick={() => {
                setMobileOpened(!mobileOpened)
              }}
              className={`${styles.menuToggler} ${mobileOpened ? styles.close : ''
                } d-lg-none`}
            >
              <span className={styles.line}></span>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
            <ul className={` ${mobileOpened ? styles.open : ''} ${styles.menuWrapper}`}>

              {navMenu && navMenu.map((itm, i) => (
                <li key={i}><a href={itm.link}>{itm.label}</a></li>
              ))}
              <div className=' d-lg-none'>
                <Button className={styles.buttonMobile} size="large" text={'Contact us'} hoverBgColor='#22B9C5' hoverColor='#fff' link={'#contact-form'} color={'#02575C'} bgColorBtn={'#D4F593'} target={''} />
              </div>
            </ul>
          </div>
          <div className='col-lg-3 col-4 d-none d-lg-flex justify-content-xl-center'>
            <Button size="large" text={'Contact us'} link={'#contact-form'} hoverBgColor='#22B9C5' hoverColor='#fff' color={'#02575C'} bgColorBtn={'#D4F593'} target={''} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default Navbar;