import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import { gql } from '@apollo/client';
import { CoreVideo as CoreVideoType } from 'generated-graphql';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import dynamic from 'next/dynamic';
import React, { useRef, useState } from 'react';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

gsap.registerPlugin(ScrollTrigger);

export default function CoreVideo(props: CoreVideoType) {
  const attributes = props.attributes;
  const videoRef = useRef<HTMLDivElement>(null);
  const isAutoPlay = attributes?.className?.includes('autoplay');
  const [isPlaying, setIsPlaying] = useState(false);

  useIsomorphicLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (videoRef.current && isAutoPlay) {
        setTimeout(() => {
          ScrollTrigger.create({
            trigger: videoRef.current,
            start: 'top center',
            end: 'bottom top',
            onToggle: (self) =>
              self.isActive ? setIsPlaying(true) : setIsPlaying(false),
          });
        }, 500)

      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div
      className={`core-video ${attributes?.className}`}

      ref={videoRef}
    >
      {!isPlaying && !isAutoPlay && (
        <img
          className="play-btn video-btn"
          src={'/images/play.png'}
          onClick={() => setIsPlaying(true)}
        />
      )}
      {isPlaying && !isAutoPlay && (
        <img
          className="pause-btn video-btn"
          src={'/images/pause.png'}
          onClick={() => setIsPlaying(false)}
        />
      )}
      <ReactPlayer
        url={attributes?.src || ''}
        playing={isPlaying}
        className="core-video-player"
        width="100%"
        height="100%"
        muted={isAutoPlay}
        controls={!isAutoPlay}
        loop={isAutoPlay}
      />
    </div>
  );
}

CoreVideo.displayName = 'CoreVideo';

CoreVideo.fragments = {
  entry: gql`
    fragment CoreVideoFragment on CoreVideo {
      attributes {
        className
        src
      }
    }
  `,
  key: `CoreVideoFragment`,
};
