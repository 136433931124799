
import Player from '@vimeo/player';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './Video.module.scss';
import { LinkedinShareButton } from 'react-share';
import Linkedin from '/public/svgs/linkedin-icon.svg';
import CopyIcon from '/public/svgs/copy.svg';

import { copyToClipboard } from '@/helpers/copy';

const Video = (props: any) => {
  const { vimeoId } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const playerContainer = useRef(null);
  const shareUrl = typeof window !== 'undefined' ? window?.location?.href : '';


  const player = useRef<any>(null);
  useLayoutEffect(() => {
    if (vimeoId && playerContainer.current) {
      player.current = new Player(playerContainer.current, {
        id: +vimeoId,
        controls: true
      });
    }
    if (player.current) {
      player.current.on('play', () => {
        setIsPlaying(true);
      });

      player.current.on('pause', () => {
        setIsPlaying(false);
      });

      player.current.on('ended', () => {
        setIsPlaying(false);
      });
    }


    return () => {
      if (player.current) {
        player.current.off('play', () => {
          setIsPlaying(true);
        });

        player.current.off('pause', () => {
          setIsPlaying(false);
        });

        player.current.off('ended', () => {
          setIsPlaying(false);
        });
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className={styles.mainVideoWrapper}>

            <div className={`${styles.playerWrapper} video-wrapper `} ref={playerContainer}>  {!isPlaying && (
              <img
                className="video-btn"
                src={'/images/play.png'}
                onClick={() => { player.current.play(); setIsPlaying(true) }}
              />
            )}
              {isPlaying && (
                <img
                  className="pause-btn video-btn"
                  src={'/images/pause.png'}
                  onClick={() => { player.current.pause(); setIsPlaying(false) }}
                />
              )}</div>
            <div className={styles.featuring}>
              <div className="d-flex mt-4 mt-lg-0">
                <button className="copyBtn me-3" style={{ color: "#fff", height: "fit-content" }} onClick={copyToClipboard}>
                  <CopyIcon className={styles.copyIcon} /> Copy Link
                </button>

                <LinkedinShareButton style={{ height: "fit-content" }} url={shareUrl}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      border: '1px solid #fff',
                      borderRadius: '50%',
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Linkedin />
                  </div>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
