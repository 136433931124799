import Footer from "@/components/ComponentsHalogen/Footer/Footer";
import Navbar from "@/components/ComponentsHalogen/Navbar/Navbar";
import { Barlow, Baloo_2 } from "next/font/google";
import Head from "next/head";
import { ReactNode } from "react";
import localFont from 'next/font/local';
import { halogenURL } from "@/helpers/halogen";

const baloo = Baloo_2({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
});
const barlow = Barlow({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
});
const iconsFont = localFont({
  src: [
    {
      path: '../../public/fonts/icons.woff',
    },
  ],
});

export const navMenu = [
  // {
  //   label: "Work",
  //   link: "/halogen/work"
  // },
  {
    label: "About us",
    link: halogenURL('about')
  },
  {
    label: "Services",
    link: halogenURL('services')
  },
  {
    label: "How we work",
    link: halogenURL('work')
  },
]



export const footerMenu = [
  // {
  //   label: "Work",
  //   link: "/halogen/work"
  // },
  {
    label: "About us",
    link: halogenURL('about')
  },
  {
    label: "Services",
    link: halogenURL('services')
  },
  {
    label: "How we work",
    link: halogenURL('work')
  },
  {
    label: "Data integrity & compliance",
    link: halogenURL('integrity')
  },
]
const buttonsFooter = [{
  text: 'Contact us',
  link: '#contact-form',
  color: '#02575C',
  bgColorBtn: '#D4F593',
  hoverBgColor: '#22B9C5',
  hoverColor: '#fff',
  target: '_self'
}];

const gdprLinks = [{ url: 'https://www.agent3.com/privacy-policy', label: 'Privacy' }, { url: 'https://www.agent3.com/cookie-policy', label: 'Cookies' }, { url: 'https://www.agent3.com/ccpa', label: 'Do not sell my information' }]

const HalogenLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <link
          rel="shortcut icon"
          href="/halogen-favicon.ico"
        />

        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

      </Head>
      <div className={barlow.className}>
        <style jsx global>{`
                :root {
                  --root-font: ${barlow.style.fontFamily};
                  --icons-font: ${iconsFont.style.fontFamily};
                  --baloo-font: ${baloo.style.fontFamily};
                }
              `}</style>
        <Navbar navMenu={navMenu} />
        {children}
        <Footer footerMenu={footerMenu} button={buttonsFooter} gdprLinks={gdprLinks} />
      </div>
    </div>
  );
}

export default HalogenLayout;