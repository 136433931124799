import styles from './TMLinkedinFloatingImg.module.scss'


const TMLinkedinFloatingImg = () => {
  return <div>
    <div className="section  position-relative">
      <img src="/images/linkedin-gamers.png" className="w-100" />
      <img className={styles.letter} src="/images/linkedin-letter.png"></img>

    </div>
  </div>

}

export default TMLinkedinFloatingImg