import dynamic from "next/dynamic";
import { useIsomorphicLayoutEffect } from "../ScrollPlayback/ScrollPlayback";
import { useRef, useState } from "react";


const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });


const TMChange = () => {


  return (
    <div className="section" >
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-6 col-lg-5  order-1 order-lg-0" >
            <img className="img-fluid" src="/images/change-gif.gif" />

          </div>
          <div className="col-lg-5 order-0 order-lg-1 d-flex flex-column justify-content-center mb-4 mb-lg-0">
            <div className="ms-lg-5 me-lg-5 p-mobile-40">
              <h3 className="mb-3">Channel-exclusive content. </h3>
              <p className="xl mt-2">
                We built a library of exclusive short cuts,
                GIFs and memes to be deployed anywhere and everywhere, making sure that right form factor was used in the right social channels.
              </p>
            </div>

          </div>
          <div className="col-sm-6 col-lg-4 order-2 order-sm-2 mt-4 mt-sm-0"
          >
            <img className="img-fluid" src="/images/change-gif-2.gif" />


          </div>
          <div className="col-lg-8 order-3 order-lg-3 mt-4 "
          >
            <img className="img-fluid" src="/images/change-gif-3.gif" />


          </div>
        </div>

      </div>

    </div>
  )

}

export default TMChange;