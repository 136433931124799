import { gql } from '@apollo/client';
import {
  CoreSeparatorAttributes,
  CoreSeparator as CoreSeparatorType,
} from 'generated-graphql';
import LazyLoad from 'react-lazy-load';

function CoreSeparator(props: CoreSeparatorType) {
  const attributes = props.attributes as CoreSeparatorAttributes;

  return (
    <div className="core-separator">
      <div className="container">
        <hr />
      </div>
    </div>
  );
}

CoreSeparator.fragments = {
  entry: gql`
    fragment CoreSeparatorFragment on CoreSeparator {
      attributes {
        backgroundColor
      }
    }
  `,
  key: `CoreSeparatorFragment`,
};

CoreSeparator.displayName = 'CoreSeparator';

export default CoreSeparator;
