import { useRef, useState } from 'react';
import styles from './A3Player.module.scss';
import { Post } from 'generated-graphql';

export interface VideoItemProps {
  maintitle?: string;
  items: Post[];
  selectedItemId: string;
  open: (videoItem: Post) => void;
}


const Playlist: React.FC<VideoItemProps> = ({
  maintitle,
  items,
  selectedItemId,
  open
}) => {

  return (
    <div className={`${styles.listWrapper} playlist-container`}>
      <h6 className='playlist-title'>{maintitle}</h6>
      <div className={`${styles.videoList} playlist-wrapper`}>
        {items.map((item) => (
          <div
            key={item.id}
            onClick={() => open(item)}
            className={`${styles.item} ${selectedItemId === item.id ? styles.activePoster : ''}`}
          >
            <img src={item.featuredImage?.node.sourceUrl || ""} alt="" />
            <div>
              <h4>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>

  )
}
export default Playlist;