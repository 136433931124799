import { gql } from '@apollo/client';
import { CoreButton as CoreButtonType } from 'generated-graphql';
import React from 'react';
import { get } from 'lodash';
import { generateStyle } from './helpers/styles';
import styles from './CoreButton.module.scss';
import UpRightArrow from 'public/svgs/up-right-arrow.svg';
import DownArrow from 'public/svgs/down-arrow.svg';
import RightArrow from 'public/svgs/right-arrow.svg';
import UpArrow from 'public/svgs/up-arrow.svg';

function CoreButton(props: CoreButtonType) {
  const attributes = props.attributes;
  // const textAlign = get(attributes, 'align', 'left');

  const classes = props?.renderedHtml?.match(
    /<a\s[^>]*class\s*=\s*['"]([^'"]*)['"][^>]*>/,
  );
  const classesString = classes ? classes[1] : '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);
  const textColor = attributes?.textColor?.includes('ast')
    ? `var(--${attributes.textColor})`
    : attributes?.textColor || '#ffffff';

  const newText = attributes?.text?.replace("↗", '');

  return (
    <a
      style={{
        ...cssObj,
      }}
      className={`me-2 mb-3 core-btn ${classesString} ${attributes?.className} ${attributes?.backgroundColor ? 'cta' : 'link'
        }`}
      href={attributes?.url || ''}
      dangerouslySetInnerHTML={{ __html: newText || "" }}
      target={attributes?.linkTarget || "_self"}
    />

  );
}

CoreButton.fragments = {
  entry: gql`
    fragment CoreButtonFragment on CoreButton {
      renderedHtml
      attributes {
        text
        backgroundColor
        url
        linkTarget
        textColor
        className
        style
      }
    }
  `,
  key: `CoreButtonFragment`,
};

CoreButton.displayName = 'CoreButton';

export default CoreButton;
