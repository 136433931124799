import { useState } from 'react';
import Link from 'next/link';
import styles from './ThisMachineNav.module.scss';

const ThisMachineNav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = (section: string) => {
    const element = document.querySelector(section);
    if (element) {
      element.scrollIntoView();

      setTimeout(() => {
        element.scrollIntoView();
      }, 300);
    }

    closeMenu();
  };

  return (
    <div className={styles.navBar}>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-lg-10 col-12 h-100">
            <div className={styles.links}>


              <Link href="/" rel="noopener noreferrer">
                <img className="pe-3" src="/svgs/this-machine.svg" />
              </Link>

              <div className={styles.hamburger} onClick={toggleMenu}>
                <div className={`${styles.bar} ${isMenuOpen ? styles.open : ''}`}></div>
                <div className={`${styles.bar} ${isMenuOpen ? styles.open : ''}`}></div>
                <div className={`${styles.bar} ${isMenuOpen ? styles.open : ''}`}></div>
              </div>
              <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.open : ''}`}>
                <a href="/#about" className='px-lg-3 px-2' onClick={() => handleClick('#about')}>About</a>
                <a href="/#work" className='px-lg-3 px-2' onClick={() => handleClick('#work')}>Work</a>
                <a href="/pov" className='px-lg-3 px-2'>POV</a>
                <div className={styles.cta}>
                  <a className='cta' href="#contact">
                    Contact us
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div className="col-lg-2 d-none d-lg-block">
            <a className="cta" href="#contact">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThisMachineNav;
