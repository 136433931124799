import { createContext, useContext, useState, ReactNode } from 'react';

interface AppContextData {
  pageType: string;
  setPageType: (pageType: string) => void;
}

const AppContext = createContext<AppContextData | undefined>(undefined);

export const useAppContext = (): AppContextData => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};

interface AppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [pageType, setPageType] = useState('test');

  const contextValue: AppContextData = {
    pageType,
    setPageType,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;