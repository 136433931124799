import { gql } from '@apollo/client';
import React from 'react';
// @ts-ignore
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { CoreMediaText as CoreMediaTextType } from 'generated-graphql';
import LazyLoad from 'react-lazy-load';

function CoreMediaText(props: CoreMediaTextType) {
  const attributes = props.attributes;
  const imageFirst = attributes?.mediaPosition === 'left';
  const blocksViewer = props?.innerBlocks ? ( // @ts-ignore
    <WordPressBlocksViewer blocks={props.innerBlocks} />
  ) : null;
  const img = (
    <img
      className="my-3 my-lg-0 img-fluid"
      src={attributes?.mediaUrl || ''}
      alt={attributes?.mediaAlt || ''}
    />
  );

  return (
    <div className="mediatextt row d-flex justify-content-center">
      <div
        className={`${
          imageFirst ? 'order-lg-1' : 'order-lg-2'
        } order-2 col-lg-5 ${!imageFirst ? 'offset-lg-1' : ''} col-12 `}
      >
        {attributes?.className?.includes('no-lazy') ? (
          img
        ) : (
          <LazyLoad threshold={0.1}>{img}</LazyLoad>
        )}
      </div>

      {props.innerBlocks && (
        <div
          className={`${
            imageFirst ? 'order-lg-2' : 'order-lg-1'
          } order-1 col-lg-6 ${imageFirst ? 'offset-lg-1' : ''} col-12`}
        >
          {blocksViewer}
        </div>
      )}
    </div>
  );
}

CoreMediaText.fragments = {
  entry: gql`
    fragment CoreMediaTextFragment on CoreMediaText {
      attributes {
        mediaId
        mediaLink
        mediaPosition
        mediaUrl
        mediaType
        mediaAlt
        className
      }
    }
  `,
  key: `CoreMediaTextFragment`,
};

CoreMediaText.displayName = 'CoreMediaText';
export default CoreMediaText;
