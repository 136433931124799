import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import lottie from 'lottie-web';
import styles from './ScrollPlayback.module.scss';
import Head from 'next/head';

gsap.registerPlugin(ScrollTrigger);
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const lottieAnimation = (
  src: string,
  lottieCont: HTMLElement,
  sectionRef: any,
) => {
  const LottieScrollTrigger = (vars: any) => {
    let playhead = { frame: 0 },
      target = gsap.utils.toArray(vars.target)[0],
      speeds = { slow: '+=2000', medium: '+=1000', fast: '+=500' } as any,
      st = {
        trigger: target,
        pin: true,
        start: 'top top',
        end: speeds[vars.speed] || '+=1000',
        scrub: 1,
      } as any,
      animation = lottie.loadAnimation({
        container: target as any,
        renderer: vars.renderer || 'svg',
        loop: false,
        autoplay: false,
        path: vars.path,
      });
    for (let p in vars) {
      // let users override the ScrollTrigger defaults
      st[p] = vars[p];
    }
    animation.addEventListener('DOMLoaded', function () {
      gsap.to(playhead, {
        frame: animation.totalFrames - 1,
        ease: 'none',
        onUpdate: () => animation.goToAndStop(playhead.frame, true),
        scrollTrigger: st,
      });
    });
  };
  LottieScrollTrigger({
    target: lottieCont,
    path: src,
    speed: 'medium',
    pin: lottieCont,
    start: 'center center',
    scrub: 1,
    markers: true,
  });
};

const MyComponent: React.FC = (props: any) => {
  const {
    file: { mediaItemUrl },
  } = props;
  const sectionRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const lottieRef = useRef<HTMLDivElement>(null);

  const [videoEl, setVideoEl] = useState<null | HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      setVideoEl(videoRef.current);
    }
  }, [videoRef]);

  useIsomorphicLayoutEffect(() => {
    if (sectionRef.current) {
      let ctx = gsap.context(() => {
        if (videoEl) {
          const duration = videoEl?.duration || 0;
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'center center',
              end: '+=600',
              scrub: true,
              pin: true,
              id: 'ScrollPlayback',
              markers: true,
              once: false,
            },
          });

          const animate = function (v: any, tl: any) {
            tl.fromTo(
              v,
              {
                currentTime: 1,
              },
              {
                currentTime: v.duration || 1,
              },
            );
          };

          if (!duration) {
            videoEl.onloadedmetadata = function () {
              animate(videoEl, tl);
            };
          } else {
            animate(videoEl, tl);
          }
        }
      }, [sectionRef, videoEl]);
      return () => {
        ctx.revert();
      };
    }
  }, [sectionRef, videoEl]);

  return (
    <>
      <Head>
        <link rel="preload" href={mediaItemUrl} as="video" type="video/mp4" />
      </Head>
      <div className={styles.scrollPlayback} ref={sectionRef}>
        {mediaItemUrl.includes('json') && (
          <div style={{ width: '100%', height: '100%' }}>
            <div
              style={{ width: '100%', height: '100%' }}
              ref={lottieRef}
            ></div>
          </div>
        )}
        {!mediaItemUrl.includes('.json') && (
          <video
            ref={videoRef}
            src={mediaItemUrl}
            muted
            loop
            playsInline
            preload="true"
          />
        )}
      </div>
    </>
  );
};

export default MyComponent;
