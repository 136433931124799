import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import dynamic from 'next/dynamic';
import React, { useRef, useState } from 'react';
import styles from './VideoBanner.module.scss';
import { Page_Builder_Sections_VideoBanner } from 'generated-graphql';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

gsap.registerPlugin(ScrollTrigger);
const VideoBanner: React.FC<Page_Builder_Sections_VideoBanner> = ({ url, cover }) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useIsomorphicLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (videoRef.current) {
        setTimeout(() => {
          ScrollTrigger.create({
            trigger: videoRef.current,
            start: 'top bottom',
            end: 'bottom top',
            onToggle: (self) =>
              self.isActive ? setIsPlaying(true) : setIsPlaying(false),
          });
        }, 500)

      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div
      ref={videoRef}
      className={styles.videoWrapper}

    >
      {cover?.sourceUrl && (
        <img
          src={cover.sourceUrl}
          alt={cover.altText || 'video-loading'}
          className={`${styles.cover} ${videoLoaded ? styles.coverHidden : ''}`}
        />
      )}
      <ReactPlayer
        url={url as string}
        playing={isPlaying}
        className={styles.player}
        width="100%"
        height="100%"
        muted={true}
        controls={false}
        loop={true}
        onReady={() => setVideoLoaded(true)}
      />
    </div>
  );
}

export default VideoBanner;



