import { gql } from '@apollo/client';
import { CoreList as CoreListType } from 'generated-graphql';
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';

function CoreList(props: CoreListType) {
  const { attributes } = props;

  return (<div className='core-list' dangerouslySetInnerHTML={{ __html: props.renderedHtml || '' }} />


    // <ul className={`${attributes?.className} core-list`}>

    //   {props.innerBlocks &&
    //     props.innerBlocks.map((li: any, idx) => {
    //       const styleString = (get(li.attributes, 'style') || '{}') as any;
    //       const style = JSON.parse(styleString);
    //       const cssObj = generateStyle(style);
    //       return (
    //         <li
    //           key={idx} // @ts-ignore
    //           dangerouslySetInnerHTML={{ __html: li?.attributes?.content }}
    //           style={cssObj}
    //           className={li?.attributes?.className}
    //         />
    //       );
    //     })}
    // </ul>
  );
}

CoreList.fragments = {
  entry: gql`
    fragment CoreListFragment on CoreList {
      renderedHtml
      attributes {
        fontSize
        className
      }
      innerBlocks {
        ... on CoreListItem {
          attributes {
            content
            style
            className
          }
        }
      }
    }
  `,
  key: `CoreListFragment`,
};

CoreList.displayName = 'CoreList';

export default CoreList;
