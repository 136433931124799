import styles from './Footer.module.scss'
import Button, { ButtonProps } from '../Button/Button';

interface FooterMenu {
  link?: string;
  label?: string;
}
interface FooterProps {
  button?: ButtonProps[];
  footerMenu?: FooterMenu[];
  gdprLinks?: { url?: string; label?: string; }[];
};


const Footer: React.FC<FooterProps> = ({ button, footerMenu, gdprLinks }) => {
  return (
    <section className={styles.footer}>
      <div className="container">
        <div className='row'>
          <div className='col-12 px-lg-0 position-relative'>
            <div className={styles.logoWrapper}>
              <img className="img-fluid no-br" src="/halogenImg/halogenLogo.png" alt="halogen logo" />
            </div>

            <div className={styles.btnWrapper}>
              {button && button.map((b, idx) => (
                <Button key={`f-btn-${idx}`} size='small' text={b.text} hoverBgColor={b.hoverBgColor} hoverColor={b.hoverColor} link={b.link} color={b.color} bgColorBtn={b.bgColorBtn} target={b.target} />
              ))}

            </div>
            <div className={styles.linkWrapper}>
              <ul>
                {footerMenu && footerMenu.map((itm, i) => (
                  <li key={i}><a href={itm.link}>{itm.label}</a></li>
                ))}
              </ul>
            </div>


          </div>

          <div className={`col-12 px-lg-0 d-lg-flex justify-content-lg-between text-light ${styles.copyrightFooter}`}>
            <div> <p>© 2024. Halogen. All rights reserved.</p></div>
            <div className='text-light'>
              {gdprLinks && gdprLinks.map((gd, i) => (
                <a key={i} href={gd.url} target="_blank" rel="noreferrer" >{gd.label}</a>
              ))}

            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
export default Footer;