import { useContext } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useRouter } from 'next/router';
import gsap from 'gsap/dist/gsap';
import TransitionContext from '@/context/TransitionContext';

// import TransitionContext from '../context/TransitionContext';


const TransitionComponent = ({ children }) => {
  const router = useRouter();
  const { toggleCompleted } = useContext(TransitionContext);

  return <>{children}</>;
  // return (
  //   <SwitchTransition>
  //     <Transition
  //       key={router.asPath}
  //       timeout={500}
  //       onEnter={(node) => {
  //         toggleCompleted(false);

  //         gsap.set(node, { autoAlpha: 0 });
  //         gsap
  //           .timeline({
  //             paused: true,
  //             onComplete: () => {
  //               toggleCompleted(true);
  //               // ScrollTrigger.refresh();
  //             }
  //           })
  //           .to(node, { autoAlpha: 1, duration: 0.6 })
  //           // .to(node, { scale: 1, duration: 0.25 })
  //           .play();


  //       }}
  //       onExit={(node) => {
  //         gsap
  //           .timeline({ paused: true })
  //           // .to(node, { scale: 0.8, duration: 0.2 })
  //           .to(node, { autoAlpha: 0, duration: 0.5 })
  //           .play();
  //       }}
  //     >
  //       {children}
  //     </Transition>
  //   </SwitchTransition>


  // );

};

export default TransitionComponent;
