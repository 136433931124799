import { gql } from '@apollo/client';
import React from 'react';
// @ts-ignore
import { WordPressBlocksViewer } from '@faustwp/blocks';
import {
  A3BlocksA3Section as A3BlocksA3SectionType,
  A3BlocksA3SectionAttributes,
} from 'generated-graphql';
import { get } from 'lodash';
import { generateStyle } from './helpers/styles';
import { InnerBlocks } from '@wordpress/block-editor';
import { css } from 'styled-components';

function A3BlocksA3Section(props: A3BlocksA3SectionType) {
  const attributes = props.attributes as A3BlocksA3SectionAttributes;

  if (!attributes) return null;

  const {
    bgGradient,
    bgMediaUrl,
    backgroundColor,
    decorationMediaUrl,
    decorationPosition,
    mobileDecoration,
    mobileDecorationSize,
  } = attributes;

  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);


  let background = backgroundColor || 'transparent';

  if (bgMediaUrl && bgGradient) {
    background = `${bgGradient}, url('${bgMediaUrl}')`;
  } else if (bgMediaUrl) {
    background = `url('${bgMediaUrl}')`;
  } else if (bgGradient) {
    background = bgGradient;
  }

  const blocksViewer = props?.innerBlocks ? ( // @ts-ignore
    <WordPressBlocksViewer blocks={props.innerBlocks} />
  ) : null;
  return (
    <div
      style={{
        backgroundSize: "cover",
        background: background,

        ...cssObj
      }}
      className={`a3-section ${attributes.className}`}
      id={attributes?.sId as string}
    >
      <div className="a3-section-wrapper">
        {decorationMediaUrl && (
          <img
            src={decorationMediaUrl}
            className={`decoration ${decorationPosition} ${!mobileDecoration ? 'd-none d-lg-block' : mobileDecorationSize
              }`}
          />
        )}
        <div className="container">
          <div className='row'>
            <div className='col-12'>
              <div className="blocks-wrapper">{blocksViewer}</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

A3BlocksA3Section.fragments = {
  entry: gql`
    fragment A3BlocksA3SectionFragment on A3BlocksA3Section {
      attributes {
        backgroundColor
        bgGradient
        bgMediaId
        bgMediaUrl
        decorationMediaId
        decorationMediaUrl
        decorationPosition
        mobileDecoration
        mobileDecorationSize
        className
        content
        customBackgroundColor
        lock
        textColor
        sId
        style
      }
    }
  `,
  key: `A3BlocksA3SectionFragment`,
};

A3BlocksA3Section.displayName = 'A3BlocksA3Section';

export default A3BlocksA3Section;
