import { Excerpt, SixthTitle, Truncated } from "@/componentsWP/StyledComponentsLib/StyledComponentsLib"
import { Category, Post } from "generated-graphql"
import { Content } from "../ResourceItems/ResourceItem"
import Link from "next/link"
import getResourceItemUrl from "@/helpers/resourceItemUrl"

const ResourceListItem = ({ item, isFirst }: { item: Post, isFirst: boolean }) => {
  const { url, contentType } = getResourceItemUrl(item)

  const getCtaLabel = (name: string) => {
    switch (name) {
      case "Video":
        return "Watch now";
      case "Infographic":
        return "View now";

      case "Ebook":
        return "Download now";

      case "Webinar":
        return "Register now";

      default:
        return "Read now";
    }

  }

  return (
    <>
      <div className="row gx-5">
        <div className={`${isFirst ? "col-lg-6" : "col-lg-12"} `}>
          <img className="w-100" src={item.featuredImage?.node.mediaDetails?.sizes?.[0]?.sourceUrl || ""} />
        </div>
        <div className={`${isFirst ? "col-lg-6" : "col-lg-12"} d-flex flex-column`}>
          <Content className="d-flex flex-column">
            <SixthTitle>
              {!isFirst ? <Truncated minHeight="64px" lines={2}>
                {item.title}
              </Truncated> : item.title}
            </SixthTitle>
            {(item.excerpt || item.customExcerpt) && (
              <Excerpt className={isFirst ? "pt-4 excerpt" : "excerpt"} style={{ color: "#0C2A31", fontSize: "18px", lineHeight: "24px" }} dangerouslySetInnerHTML={{ __html: item.excerpt || item.customExcerpt || "" }} />
            )}
            {item.blogSettings?.externalUrl?.url ? <a style={{ width: "fit-content" }} href={item.blogSettings?.externalUrl?.url} target='_blank' rel='noreferrer' className="cta-outline mt-4">{getCtaLabel(contentType?.name as string)}</a> : <Link style={{ width: "fit-content" }} href={url} className="cta-outline mt-4" > {getCtaLabel(contentType?.name as string)}</Link>}

          </Content>

        </div>
      </div>

    </>
  )
}

export default ResourceListItem