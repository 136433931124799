import { gql } from '@apollo/client';
import { CoreList as CoreListType } from 'generated-graphql';
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';

function CoreTable(props: any) {

  return (<div dangerouslySetInnerHTML={{ __html: props.renderedHtml || '' }} />);
}

CoreTable.fragments = {
  entry: gql`
    fragment CoreTableFragment on CoreTable {
      renderedHtml
    }
  `,
  key: `CoreTableFragment`,
};

CoreTable.displayName = 'CoreTable';

export default CoreTable;
