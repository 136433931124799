import { gql } from '@apollo/client';
import { CoreParagraph as CoreParagraphType } from 'generated-graphql';
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';
import DownArrow from 'public/svgs/down-arrow.svg';
import RightArrow from 'public/svgs/right-arrow.svg';
import UpArrow from 'public/svgs/up-arrow.svg';

function CoreParagraph(props: CoreParagraphType) {
  const attributes = props.attributes;
  const textAlign = get(attributes, 'align', 'left');

  const classes = get(attributes, 'cssClassName') || '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const iconColor = attributes?.textColor
    ? `var(--${attributes?.textColor})`
    : '#000';
  const iconStyle = {
    fill: iconColor,
    stroke: iconColor,
  };
  const keyword = 'order';
  const regex = new RegExp(`\\b${keyword}\\S*`, 'gi');
  const orderClasses = classes.match(regex)?.join(' ') || '';
  const otherClasses = classes.replace(regex, '').trim();

  return (
    <div
      className={`d-flex justify-content-between align-items-end ${orderClasses}`}
    >
      <p
        className={otherClasses}
        style={{
          // @ts-ignore
          textAlign,
          width: '100%',
          ...cssObj,
        }}
        dangerouslySetInnerHTML={{ __html: attributes?.content || '' }}
      />
      {classes.includes('arrow-up') && <UpArrow style={iconStyle} />}
      {classes.includes('arrow-down') && <DownArrow style={iconStyle} />}
      {classes.includes('arrow-right') && <RightArrow style={iconStyle} />}
    </div>
  );
}

CoreParagraph.fragments = {
  entry: gql`
    fragment CoreParagraphFragment on CoreParagraph {
      attributes {
        content
        cssClassName
        textColor
        align
        style
      }
    }
  `,
  key: `CoreParagraphFragment`,
};

CoreParagraph.displayName = 'CoreParagraph';
export default CoreParagraph;
