import { bannerFields } from '@/componentsWP/Banner/Banner';

export const SEOFields = `
seo {
  canonical
  title
  metaDesc
  fullHead
  readingTime
}
`;

export const builderFields = (entity = 'Page') => `
builder {
  sections {
    ... on ${entity}_Builder_Sections_Banner {
      ${bannerFields}
    }
    ...on ${entity}_Builder_Sections_ExpandableBoxes {
      text
      boxes {
        iconColor
        shortText
        smallBox
        iconBackground
        wrapperUrl
        backgroundImage {
          mediaItemUrl
        }
        expandedIconColor
        expandedIconBackground
        expandedBackgroundImage {
          mediaItemUrl
        }
      }
    }
    ...on ${entity}_Builder_Sections_LogosSlider {
      text
      logos {
        logo {
          mediaItemUrl
        }
      }
    }
    ...on ${entity}_Builder_Sections_Featured {
      text
      videos {
        name
        videoUrl
        caseStudyUrl

      }
    }
    ... on ${entity}_Builder_Sections_ScrollPlayback {
      file {
        mediaItemUrl
      }
    }
    ... on ${entity}_Builder_Sections_Video {
      vimeoId 
      transcript
      videoLength
    }
    ... on ${entity}_Builder_Sections_VideoBanner {
      url 
      cover {
        sourceUrl
        altText
        }
      
    }
    ... on ${entity}_Builder_Sections_Resources {
      title
      layout
      description
      items {
        ... on Work {
          title
          excerpt
          slug
          id
          works {
            client
            }
          featuredImage {

            node {
              sourceUrl
              altText
              mediaDetails {
                sizes(include: [ITEM_THUMB_X2]) {
                  sourceUrl
                }
              }
            }
          }
        }
        ... on Post {
          title
          excerpt
          customExcerpt
          slug
          id
          builder {
            sections {
              ... on Post_Builder_Sections_Video {
                vimeoId
              }
            }
          }
          blogSettings {
            externalUrl {
              url
              title
            }
          }
          featuredImage {

            node {
              sourceUrl
              altText
              mediaDetails {
                sizes(include: [ITEM_THUMB_X2]) {
                  sourceUrl
                }
              }
            }
          }
          categories {
            nodes {
              parent {
                node {
                  name
                  slug
                }
              }
              name
              id
              slug
            }
          }
        }
      }

    }
   
    ... on ${entity}_Builder_Sections_Results {
      copy
      stat {
        value
        text
        wordValue
      }
      capabilities {
        ... on Capability {
          title
        }
      }
    }
    
  }
}
`;
