import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './BannerStandard.module.scss';
import { Page_Builder_Sections_Banner } from 'generated-graphql';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { useIsomorphicLayoutEffect } from '../ScrollPlayback/ScrollPlayback';


const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const BannerStandard: React.FC<Page_Builder_Sections_Banner> = (props) => {
  const {
    copy,
    type = 'full',
    backgroundColor = '#ffffff',
    background,
    image,
    video,
    videoUrl,
    mobileBackground
  } = props;

  // const isDesktop = 
  // const [isDesktop, setIsDesktop] = useState<boolean>(true);
  // const isMobile = typeof window === "undefined" || window.innerWidth > 500;
  // const [isMobile, setIsMobile] = useState<boolean>(false);


  useIsomorphicLayoutEffect(() => {
    const showMobileBackground = () => {
      const bannerBackgroundEl = document.querySelector(".bannerBackground") as HTMLElement;
      if (bannerBackgroundEl && mobileBackground?.sourceUrl && window.innerWidth < 600) {
        bannerBackgroundEl.style.backgroundImage = `url(${mobileBackground?.sourceUrl})`;
      } else {
        if (background?.sourceUrl) {
          bannerBackgroundEl.style.backgroundImage = `url(${background?.sourceUrl})`;
        }

      }
    }

    showMobileBackground();

    window.addEventListener("resize", showMobileBackground);

    return () => {
      window.removeEventListener("resize", showMobileBackground);
    }
  }, [mobileBackground?.sourceUrl, background?.sourceUrl]);

  // console.log('isDesktop', isDesktop)
  const bannerRef = useRef(null);

  const router = useRouter();
  const isSolutionsPage = router?.asPath === "/solutions"

  const backgroundImg = background?.sourceUrl || "";
  // if (isMobile && mobileBackground?.sourceUrl) {
  //   backgroundImg = mobileBackground.sourceUrl
  // }

  // console.log('backgroundImg', backgroundImg);
  // const videoUrlToShow = video?.mediaItemUrl || videoUrl;
  // const [ready, setReady] = useState<boolean>(false);
  // const [showVideo, setShowVideo] = useState<boolean>(false);

  const copyElement = copy && (
    <div className="container">
      <div className={styles.content}>
        <div className="row">
          <div className={`col-12 col-lg-10 pe-lg-0`}>
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: copy || '' }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Head>
        <link rel="dns-prefetch" href="https://player.vimeo.com/" />
        {background?.sourceUrl && <link rel="prefetch" href={background?.sourceUrl} />
        }
        {mobileBackground?.sourceUrl && <link rel="prefetch" href={mobileBackground?.sourceUrl} />
        }
      </Head>

      <section className={`${styles.banner} banner-standard`} ref={bannerRef}>
        <div
          className={`bannerBackground ${styles.bannerCont} ${isSolutionsPage ? styles.solutionsBanner : ''}`}
          style={{
            backgroundColor: backgroundColor || '#000000',
            backgroundImage: `url(${backgroundImg})`
          }}
        >
          <div className={`${styles.textContent}`}>
            {copyElement}
          </div>
        </div>
      </section>
      <div className="heroEnd"></div>
    </div>
  );
};

export const bannerFields = `
  __typename
  background {
    sourceUrl
  }
  backgroundColor
  copy
  hubspotId
  type
  image {
    sourceUrl
  }
  video {
    mediaItemUrl
  }
  videoUrl
`;

export default BannerStandard;