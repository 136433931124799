import styles from './TMLinkedinIntro.module.scss';

const TMLinkedinIntro = () => {

  return <div className={styles.linkedin}>
    <div className="section p-mobile-40">
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-sm-7 col-lg-8'>
            <div className={styles.firstEl}>
              <p className='intro' style={{ marginBottom: 60 }}>
                LinkedIn wanted one of the world’s biggest gaming brands to spend more on their platform. They had the research, the stats, and a motivated sales team. But they needed our help opening the door to establish their credibility and land the big pilot deal.
              </p>
              <p className='xl mb-3'>
                The gaming brand misunderstood the value of LinkedIn as a channel for reaching current and potential customers. They thought LinkedIn was all business.
              </p>
              <p className='xl mb-3'>
                To combat this, LinkedIn got research to prove that 55 million LinkedIn members are both professionals and gamers, many ready to spend $$$. The challenge was engaging and convincing a skeptical customer that LinkedIn had something they wanted.
              </p>
              <p className='xl mb-3'>
                Our creative concept illustrated the overlap between professionals and gamers.
              </p>
              <p className='xl'>
                We created interactive sales enablement tools for the LinkedIn team, with custom data and narrative points.
              </p>
            </div>
          </div>
          <div className='col-12 col-sm-5 col-lg-4 d-flex align-items-center'>
            <div className={styles.secondEl}>
              <div className={styles.percentage}>
                <img src="/images/linkedin-tm.png" alt="81 percent" />
                <p className={styles.description}>
                  of LinkedIn gamers use LinkedIn at least once per week and are more engaged than LinkedIn’s general member base.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


}
export default TMLinkedinIntro