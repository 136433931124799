import React, { createContext } from 'react';
import { useState } from 'react';
import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';


const TransitionContext = createContext({ completed: false });
gsap.registerPlugin(ScrollTrigger);

export const TransitionProvider = ({ children }) => {
  const [completed, setCompleted] = useState(false);


  const toggleCompleted = (value) => {
    setCompleted(value);
  };

  useIsomorphicLayoutEffect(() => {
    if (completed) {
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 1000);

    }
  }, [completed]);


  return (
    <TransitionContext.Provider
      value={{
        // @ts-ignore
        toggleCompleted,
        completed,
      }}
    >
      {children}
    </TransitionContext.Provider>
  );
};

export default TransitionContext;
