import { gql } from '@apollo/client';
import { CoreQuote as CoreQuoteType } from 'generated-graphql';
import { get } from 'lodash';
import styles from './CoreQuote.module.scss';

import React from 'react';
import { generateStyle } from './helpers/styles';

export default function CoreQuote(props: CoreQuoteType) {
  const attributes = props.attributes;

  const classes = props?.renderedHtml?.match(
    /<blockquote\s[^>]*class\s*=\s*['"]([^'"]*)['"][^>]*>/,
  );

  const classesString = classes ? classes[1].split(' ').join(' ') : '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  const removedBeginnningQuote = attributes?.value?.replace(/(?<=<p>)“/g, "")
  return (
    <blockquote
      className={`${styles.blockquote} ${classesString}`}
      style={cssObj}
      dangerouslySetInnerHTML={{ __html: removedBeginnningQuote || "" }}
    />
  );
}

CoreQuote.fragments = {
  entry: gql`
    fragment CoreQuoteFragment on CoreQuote {
      renderedHtml
      attributes {
        citation
        value
      }
    }
  `,
  key: `CoreQuoteFragment`,
};
