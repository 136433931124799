import { getEntity } from 'gql/common/blocks';
import MainLayout from '@/layouts/Main';
import BlockMix from '@/components/BlockMix/BlockMix';
import { useEffect } from 'react';

export default function Component(props: any) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname;
      if (
        pathname !== '/preview' &&
        window.location.href.includes('preview=true')
      ) {
        window.location.href = window.location.href.replace(
          pathname,
          '/preview',
        );
      }
    }
  }, []);

  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  const { primaryMenuItems, footerMenuItems } = props.data;
  const { editorBlocks, builder, title, slug } = props.data.page;
  // console.log('slug', slug, title, props.data.page);

  return (
    <>
      <MainLayout
        pageBlocks={props.data.page}
        menus={{
          main: primaryMenuItems.menuItems.nodes,
          footer: footerMenuItems.menuItems.nodes,
        }}
      >
        {/* <p style={{ marginTop: 140 }}>WP-TEMPLATE</p> */}
        {builder?.sections.length === 0 && (
          <div style={{ marginTop: 180 }}>
            <div className="container">
              <h1>{title}</h1>
            </div>
          </div>
        )}

        <BlockMix blocksData={editorBlocks} acfData={builder?.sections} />
      </MainLayout>
    </>
  );
}

Component.variables = ({ databaseId }: { databaseId: number }, ctx: any) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = getEntity();
