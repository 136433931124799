import { gql } from '@apollo/client';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { CoreColumn as CoreColumnType } from 'generated-graphql';
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';
import { useRef } from 'react';
import Link from 'next/link';

function CoreColumn(props: CoreColumnType) {
  const attributes = props.attributes as any;
  const columnRef = useRef<HTMLDivElement>(null);

  const blocksViewer = props?.innerBlocks ? ( // @ts-ignore
    <WordPressBlocksViewer blocks={props.innerBlocks} />
  ) : null;

  const classes = get(attributes, 'cssClassName') || '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);
  let url = ''

  if (classes.includes("col-link") && columnRef.current) {
    const firstLink = columnRef.current.querySelector('a')
    url = firstLink?.href || "";
  }

  return (
    <div
      ref={columnRef}
      className={`core-column ${classes}`}
      style={{
        backgroundColor: attributes?.backgroundColor || 'transparent',
        maxHeight: '100%',
        width: attributes?.widthString || '100%',
        maxWidth: attributes?.widthString || '100%',
        ...cssObj,
      }}
    >
      {url ? <Link className='d-flex flex-column h-100' href={url}>{blocksViewer}</Link> : blocksViewer}
    </div>
  );
}

CoreColumn.fragments = {
  entry: gql`
    fragment CoreColumnFragment on CoreColumn {
      attributes {
        backgroundColor
        cssClassName
        widthString: width
        layout
        style
      }
    }
  `,
  key: `CoreColumnFragment`,
};

CoreColumn.displayName = 'CoreColumn';

export default CoreColumn;
