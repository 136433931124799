export const presetGenerate = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      const propName = `padding-${key
        .replace(/([A-Z])/g, '-$1')
        .toLowerCase()}`;
      const propValue = value.startsWith('var:preset|')

        ? `var(--wp--preset--${value
          .replace(/var:preset\|/g, '')
          .replace(/\|/g, '--')})`
        : value;
      return `${propName}:${propValue};`;
    })
    .join('');
};


export const generateStyle = (style) => {
  let cssStr = '';

  if (style?.spacing?.margin) {
    cssStr += presetGenerate(style?.spacing?.margin);
  }


  if (style?.spacing?.padding) {
    cssStr += presetGenerate(style?.spacing?.padding);
  }

  if (style?.typography?.fontSize) {
    cssStr += `font-size:${style.typography.fontSize};`;
  }

  if (style?.typography?.fontWeight) {
    cssStr += `font-weight:${style.typography.fontWeight};`;
  }

  const cssObj = cssStr.split(';')
    .filter(Boolean)
    .reduce((acc, item) => {
      const [key, value] = item.split(':');
      const propName = `${key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())}`;
      // @ts-ignore
      acc[propName] = value;
      return acc;
    }, {});

  return cssObj;

}
