
import styles from './Button.module.scss'

export interface ButtonProps {
  text: string;
  link: string;
  color: string;
  bgColorBtn: string;
  target: string;
  className?: string;
  onClick?: () => void;
  size?: 'small' | 'large';
  hoverBgColor?: string;
  hoverColor?: string;
}
const Button: React.FC<ButtonProps> = ({ text, link, color, bgColorBtn, target, className, onClick, size, hoverColor, hoverBgColor }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }
  const buttonSize = size === 'large' ? styles.large : styles.small;


  return (
    <a className={`${className} ${styles.button} ${buttonSize} `}
      style={{ color: color, backgroundColor: bgColorBtn }}
      target={target}
      href={link}
      onClick={handleClick}
      onMouseEnter={(e) => {
        if (hoverBgColor) {
          e.currentTarget.style.backgroundColor = hoverBgColor;
        }
        if (hoverColor) {
          e.currentTarget.style.color = hoverColor;
        }
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = bgColorBtn;
        e.currentTarget.style.color = color;
      }}
    >
      {text}</a>
  )
}
export default Button;