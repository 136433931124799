import { toast } from 'react-toastify';

export const copyToClipboard = async () => {
  const url = window.location.href;
  try {
    await navigator.clipboard.writeText(url);
    toast.success('Copied URL!', {
      theme: 'colored',
    });
  } catch (error) {
    console.error('Failed to copy URL to clipboard:', error);
  }
};