import gql from 'graphql-tag';

import { SEOFields, builderFields } from './common/fields';
import { allEntries, allKeys } from './common/blocks';

const builder = builderFields();
export const CORE_PAGE_FIELDS = gql`
  fragment CorePageFields on Page {
    id
    title
    slug
    databaseId
    inlineCss
    ${SEOFields}
    ${builder}
  }
`;

export const PAGE = gql`
  ${CORE_PAGE_FIELDS}
  query page($id: ID!) {
    page(id: $id, idType: URI) {
      ...CorePageFields
    }
  }
`;

export const PAGES = gql`
  ${CORE_PAGE_FIELDS}
  query pages {
    pages {
      nodes {
        ...CorePageFields
      }
    }
  }
`;

export const BRANDS_PAGES = gql`
  query brands_pages($nameIn: [String]) {
    pages(where: { nameIn: $nameIn }) {
      nodes {
        title
        slug
      }
    }
  }
`;
export const CHILD_PAGES = gql`
  query parent_page($id: ID!) {
    page(id: $id, idType: URI) {
      children {
        nodes {
          slug
        }
      }
    }
  }
`;
