import React, { useState, FC } from 'react';
//@ts-ignore
import GoogleMapReact from 'google-map-react-concurrent';
import Select from 'react-select';
import styles from './Map.module.scss';
import PinIcon from './icons/Pin.svg';
import jsonLocations from './locations.json' assert { type: 'json' };

type PinProps = {
  lat?: number;
  lng?: number;
  name?: string;
};

type Location = {
  name: string;
  address: string;
  address2: string;
  coordinats: {
    lat: number;
    lng: number;
  };
};

type MapStyle = {
  featureType: string;
  elementType: string;
  stylers: { [key: string]: string | number }[];
};

type MapProps = {
  zoom?: number;
  mapStyle?: string;
  locations?: string;
};

const ZOOM = 20;

const Pin: FC<PinProps> = ({ lat, lng, name }) => {
  return <PinIcon />;
};

const style = {
  control: (base: any, state: any) => {
    return {
      ...base,
      border: '1px solid black',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid black',
      },
    };
  },
  option: (styles: any, { isSelected }: { isSelected: any }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#000000' : '#ffff',
      color: isSelected ? '#ffff' : '#000000',
      '&:hover': {
        backgroundColor: isSelected ? '#000000' : '#F2F4F7',
      },
    };
  },
};

export const defaultValues: MapProps = {
  zoom: ZOOM,
  locations: JSON.stringify(jsonLocations),
  // mapStyle: JSON.stringify(jsonMapStyle),
};

export const Map: FC<MapProps> = ({
  locations = defaultValues.locations,
  mapStyle = defaultValues.mapStyle,
  zoom = defaultValues.zoom,
}) => {
  const mapStyleDecoded: MapStyle[] = mapStyle ? JSON.parse(mapStyle) : '';
  const locationsDecoded: Location[] = locations ? JSON.parse(locations) : '';

  const [location, setLocation] = useState(locationsDecoded[0]);

  return (
    <div className="a3-section">
      <div className="container">
        <div style={{ marginBottom: 65 }}>
          <p className="md semibold" style={{ paddingBottom: 16 }}>Find Us</p>
          <h4 className='semibold'>Our offices</h4>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 ">
            <div className={`${styles.scroll}`}>
              <ul className={styles.locations}>
                {locationsDecoded.map((l, idx) => (
                  <li
                    onClick={() => setLocation(l)}
                    key={`map-location-${idx}`}
                    style={{ cursor: 'pointer' }}
                    className={location.name === l.name ? styles.active : ''}
                  >
                    <p className='xl semibold'>{l.name}</p>
                    <p className='md'> {l.address}</p>
                    <p className='md'> {l.address2}</p>

                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-8 ">
            <div
              className={styles.map}
              style={{ borderRadius: '16px', overflow: 'hidden' }}
            >
              <GoogleMapReact
                options={{
                  styles: mapStyleDecoded,
                }}
                bootstrapURLKeys={{
                  key: 'AIzaSyD_X71qAlrFVkZn5psD5PbVODTM_wABLLI',
                }}
                defaultCenter={location.coordinats}
                center={location.coordinats}
                defaultZoom={zoom}
              >
                <Pin
                  lat={location.coordinats.lat}
                  lng={location.coordinats.lng}
                  name={location.name}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fields = {
  id: 'Map',
  label: 'Map form',
  type: 'container',
  renderer: 'form',
  elements: {
    main: {
      type: 'container',
      renderer: 'div',
      htmlClass: 'row',
      elements: {
        zoom: {
          name: 'zoom',
          label: 'Zoom',
          type: 'field',
          renderer: 'number',
          fieldType: 'text',
        },
        locations: {
          name: 'locations',
          label: 'Locations',
          type: 'field',
          renderer: 'json',
          fieldType: 'text',
        },
        mapStyle: {
          name: 'mapStyle',
          label: 'Map styles',
          type: 'field',
          renderer: 'json',
          fieldType: 'text',
        },
      },
    },
    save: {
      type: 'field',
      renderer: 'button',
      name: 'save',
      content: 'Save',
      fieldClass: 'btn-primary btn',
      buttonType: 'submit',
    },
  },
};

export default Map;
