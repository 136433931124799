import React, { useState, useEffect } from 'react';
import styles from './A3Player.module.scss';
import VideoDetails from './VideoDetails';
import Playlist from './Playlist'
import PlayIcon from 'public/svgs/play.svg';
import dynamic from 'next/dynamic';
import { Maybe, Post } from 'generated-graphql';
import debounce from 'lodash/debounce';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export interface A3ResourceProps {
  items?: Maybe<Maybe<Post>[]>;
  description?: Maybe<string>;
}

const A3Player: React.FC<A3ResourceProps> = ({
  description,
  items,
}) => {

  const [currentVideoItem, setCurrentVideoItem] = useState<Maybe<Post> | undefined>(items?.[0]);
  const [play, setPlay] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const open = (videoItem: Post) => {
    setCurrentVideoItem(videoItem);
    setPlay(true);
  };

  const handleResize = () => {
    if (window.innerWidth < 992) {
      return;
    }
    const playlistContainer = document.querySelector('.playlist-container') as HTMLElement;
    const playerWrapper = document.querySelector('.player-wrapper') as HTMLElement;
    const playlist = document.querySelector('.playlist-wrapper') as HTMLElement;
    const heading = document.querySelector('.playlist-title') as HTMLElement;

    if (!playerWrapper || !playlist || !heading || !playlistContainer) {
      return;
    }
    const containerPadding = window.getComputedStyle(playlistContainer).paddingBottom
    playlist.style.height = `${playerWrapper.offsetHeight - heading.offsetHeight - parseInt(containerPadding)}px`
  }
  const debouncedResize = debounce(handleResize, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedResize);
    setReady(true);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  useEffect(() => {
    setReady(false);
  }, [currentVideoItem?.id]);
  if (!items) {
    return null;
  }
  return (
    <section className={styles.A3Player}>
      <div className="container-xxl">
        <div className='row'>
          <div className='col-lg-12' dangerouslySetInnerHTML={{ __html: description || "" }} />
        </div>
        <div className="row align-items-top  py-3">
          <div className="col-12 col-xl-8 col-lg-7 player-wrapper" style={{ height: "fit-content" }}>
            <div className={styles.wrapper}>
              <div className={styles.videoWrapper} style={{ backgroundImage: `url(${currentVideoItem?.featuredImage?.node.sourceUrl})` }} onClick={() => { setPlay(true); }}>
                <div className={styles.action} style={{ opacity: ready || play ? '0' : '1' }}>
                  <PlayIcon />
                </div>
                <div style={{ opacity: ready ? '1' : '0' }}>
                  {currentVideoItem && currentVideoItem.builder?.sections?.[0]?.__typename === "Post_Builder_Sections_Video" && (
                    <ReactPlayer
                      url={`https://player.vimeo.com/video/${currentVideoItem.builder?.sections?.[0].vimeoId}`}
                      playing={play}
                      controls
                      width="100%"
                      height="100%"
                      onPlay={() => {
                        setTimeout(() => { setReady(true); }, 300);
                      }}
                      onEnded={() => {
                        setCurrentVideoItem((current) => {
                          const currentIndex = items.findIndex((item) => item?.id === current?.id);
                          const nextIndex = currentIndex + 1;
                          if (items[nextIndex]) {
                            return items[nextIndex];
                          }

                          return current;
                        });
                      }}
                    />

                  )}
                </div>
              </div>

              <VideoDetails videoItem={currentVideoItem as Post} />
            </div>
          </div>
          <div className="col-12 col-xl-4 col-lg-5  mt-5 mt-lg-0">
            <Playlist maintitle='Explore more' items={items as Post[]} selectedItemId={currentVideoItem?.id as string} open={open} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default A3Player;
