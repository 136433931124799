import dynamic from "next/dynamic";
import { useIsomorphicLayoutEffect } from "../ScrollPlayback/ScrollPlayback";
import { useRef, useState } from "react";
import gsap from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });
gsap.registerPlugin(ScrollTrigger);

const TMAdobe = () => {

  const [videosPlaying, setVideosPlaying] = useState(false);
  const sectionRef = useRef(null)
  useIsomorphicLayoutEffect(() => {

    let ctx = gsap.context(() => {

      if (sectionRef.current) {
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top center',
          end: 'bottom top',
          onToggle: (self) =>
            self.isActive ? setVideosPlaying(true) : setVideosPlaying(false),
        });
      }



    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="section" >
      <div className="container">
        <div className="row d-flex justify-content-center" ref={sectionRef}>
          <div className="col-sm-5 order-1 order-lg-0 mb-4 mb-sm-0" >
            <ReactPlayer
              url="/videos/tm-adobe.mp4"
              playing={videosPlaying}
              className="core-video-player"
              width="100%"
              height="fit-content"
              muted
              controls={false}
              loop={true}
            />

          </div>
          <div className="col-lg-5 order-0 order-lg-1 d-flex flex-column justify-content-center mb-4 mb-lg-0">
            <div className="p-mobile-40 ">
              <p className="xl">Much like pinball, the objective in the game of personalisation is to not drop the ball. Get it right, and there’s a chance to win big with customers. </p>
              <p className="xl mt-2">
                As the users progressed through the game they scored points, before unlocking a bonus round that quizzed them on their personalisation knowledge.
              </p>
            </div>

          </div>
          <div className="col-sm-4 order-2 mb-4 mb-sm-0 mt-lg-4"
          >
            <ReactPlayer
              url="/videos/tm-adobe-2.mp4"
              playing={true}
              className="core-video-player"
              width="100%"
              height="fit-content"
              muted
              controls={false}
              loop={true}
            />

          </div>
          <div className="col-lg-8 order-3 order-lg-3 mt-sm-4"
          >
            <ReactPlayer
              url="/videos/tm-adobe-3.mp4"
              playing={videosPlaying}
              className="core-video-player box-shadow"
              width="100%"
              height="fit-content"
              muted
              controls={false}
              loop={true}
            />

          </div>
        </div>

      </div>

    </div>
  )

}

export default TMAdobe;