import React from 'react';
import styles from './BannerSimple.module.scss';
import { Page_Builder_Sections_Banner } from 'generated-graphql';
import dynamic from 'next/dynamic';
import Head from 'next/head';
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });


const BannerSimple: React.FC<Page_Builder_Sections_Banner> = (props) => {
  const {
    copy,
    backgroundColor,
    background,
  } = props;



  return (

    <section>

      <div
        className={styles.bannerSimple}
        style={{
          backgroundColor: backgroundColor || '#000000',
          backgroundImage: background?.sourceUrl
            ? `url("${background?.sourceUrl}")`
            : '',
        }}
      >
        <div className="container">
          <div className="row">
            <div className={`col-12`}>
              <div
                className={styles.copy}
              >
                <div dangerouslySetInnerHTML={{ __html: copy || '' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};


export default BannerSimple;