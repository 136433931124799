export enum UTMParam {
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  UTM_CHANNEL = 'utm_channel',
}

export type UTMsType = {
  [key in UTMParam]: string;
};

type FormikUTMs = {
  [value in UTMParam]: {
    name: string;
    label: string;
    type: string;
    renderer: string;
    fieldType: string;
    defaultValue: string;
  };
};
export const getFormikUTMs = (cookies: UTMsType) => {
  const utms = {} as FormikUTMs;
  const common = {
    type: 'field',
    renderer: 'text',
    fieldType: 'text',
  };

  Object.values(UTMParam).forEach((v) => {
    utms[v] = Object.assign({}, common, {
      name: v,
      label: v,
      defaultValue: cookies[v] || '',
    });
  });

  return utms;
};
