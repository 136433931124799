// import frontPage from "./front-page";
import page from "./page";
// import services from "./single-services";
// import resource from "./single-resource";
// import single from "./single";
// import archive from "./archive";
// import singular from "./singular"

export default {
  // "single-services": services,
  // "single-resource": resource,
  page,
  // single,
  // archive,
  // singular
};
