import Slider from 'react-slick';
import styled from 'styled-components';

import { Page_Builder_Sections_LogosSlider, Page_Builder_Sections_LogosSlider_Logos, Post } from 'generated-graphql';
import { Img } from '../StyledComponentsLib/StyledComponentsLib';
import React, { useRef } from 'react';
import { useIsomorphicLayoutEffect } from '../ScrollPlayback/ScrollPlayback';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import LazyLoad from 'react-lazy-load';

const WrapperSlider = styled.div`
    .slick-list { 
      .slick-track {
          display: flex;
          padding: 8px 0px;
          align-items: center;
          .slick-slide {
            img {
             margin: 0 auto;
             width: 100%;
             max-width: 300px;
             border-radius:0;
             padding: 0 20px;

             @media screen and (max-width: 900px) {
                max-width: 200px;
                /* height: 40px; */
             }
            }
          }
      }
    }
`;

const LogosSlider: React.FC<Page_Builder_Sections_LogosSlider> = (props) => {
  const { logos, text } = props;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    centerMode: true,
    cssEase: "linear",
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  }

  // useIsomorphicLayoutEffect(() => {
  //   const animEl = document.querySelector(`.logos-slider`);


  //   const ctx = gsap.context(() => {
  //     const t1 = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: animEl,
  //         // scrub: 1,
  //         // toggleActions: 'play play play reverse',
  //         // markers: true,
  //         // pin: true,
  //         // anticipatePin: 1,
  //         start: "bottom bottom",
  //         end: "center center",
  //         id: 'Logos Slider',
  //       },
  //     });

  //     t1.fromTo(animEl, {
  //       y: '50px',
  //       opacity: 0,
  //       // background: 'red',
  //       // transform: 'rotate(180deg)',

  //     }, {
  //       y: 0,
  //       opacity: 1,

  //     });
  //   });

  //   return () => {
  //     ctx.kill();
  //     ScrollTrigger.refresh();
  //   };
  // }, []);

  return (
    <div className='section logos-slider'>
      <LazyLoad offset={600}>
        <WrapperSlider>
          {text && <div style={{ marginBottom: 30 }} dangerouslySetInnerHTML={{ __html: text }}></div>}
          <Slider {...settings}>
            {logos?.map((item: Page_Builder_Sections_LogosSlider_Logos | null | undefined, idx: number) =>
              item?.logo?.mediaItemUrl && (<div key={idx + 'logo'} className='p-3'>
                <Img TypeImgRound src={item.logo.mediaItemUrl} />
              </div>)
            )}
          </Slider>

        </WrapperSlider>
      </LazyLoad>
    </div >
  )
}

export default LogosSlider;
