// @ts-nocheck
import ResourceList from "@/components/ResourceList/ResourceList"
import { Page_Builder_Sections_Resources, Post } from "generated-graphql"
import A3Player from "../A3Player/A3Player"
import SectionSlider from "../SectionSlider/SectionSlider";

const Resources: React.FC<Page_Builder_Sections_Resources> = (props) => {
  const { layout } = props;

  let content = null;
  switch (layout) {
    case "player":
      content = <A3Player {...props} />;
      break;
    case "list": {
      content = <ResourceList {...props} />;
      break;
    }
    case "slider": {
      content = <SectionSlider {...props} />;
      break;
    }
    default: {
      content = <ResourceList {...props} />
    }
  }


  return (
    <div className="section">
      {content}
    </div>
  )
}

export default Resources