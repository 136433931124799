import { gql } from '@apollo/client';
import { CoreEmbed as CoreEmbedType } from 'generated-graphql';
import get from 'lodash/get';
import { generateStyle } from './helpers/styles';

function CoreEmbed(props: CoreEmbedType) {
  const attributes = props.attributes;
  const textAlign = get(attributes, 'align', 'left');

  // const classes = get(attributes, 'cssClassName') || '';
  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);

  return (
    <div className='py-5'>
      <div className="responsive-iframe">
        <iframe src={attributes?.url || ''}></iframe>
      </div>
    </div>
  );
}

CoreEmbed.fragments = {
  entry: gql`
    fragment CoreEmbedFragment on CoreEmbed {
      renderedHtml
      attributes {
        url
      }
    }
  `,
  key: `CoreEmbedFragment`,
};

CoreEmbed.displayName = 'CoreEmbed';

export default CoreEmbed;
