import { Category, Post, Work } from 'generated-graphql';

const getResourceItemUrl = (item: Post | Work) => {
  let target = '_self';

  if (item.__typename === 'Work') {
    return { url: `/work/${item.slug}`, contentType: null, target };
  }

  if (item.__typename === 'Post') {
    const seriesCategory = item.categories?.nodes.find(
      (c) => c.parent?.node.name === 'Series',
    );
    const contentType = item?.categories?.nodes?.find(
      (c: Category) => c.parent?.node.name === 'Content Type' && c.name,
    );

    let url = `/resources/${item.slug}`;

    // External
    if (item.blogSettings?.externalUrl?.url) {
      url = item.blogSettings?.externalUrl?.url;
      target = '_blank';
      return { url, contentType, target };
    }

    if (contentType?.name === 'Blog') {
      url = `/blog/${item.slug}`;
    }
    if (seriesCategory) {
      url = `/resources/series/${seriesCategory.slug}/${item.slug}`;
    }
    return { url, contentType, target };
  }

  return { url: '', contentType: null, target };
};

export default getResourceItemUrl;
