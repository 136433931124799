export const makeSameHeight = (selectors: string[], wrapper: HTMLElement) => {
  const elements = selectors.map((selector) =>
    wrapper.querySelectorAll(selector),
  ) as NodeListOf<HTMLElement>[];
  console.log(elements, 'elements');
  elements.forEach((innerEls) => {
    innerEls.forEach((element) => {
      element.style.minHeight = '0';
    });
  });

  const heights = elements.map((elements) =>
    Array.from(elements).map((element) => element.clientHeight),
  );
  const maxHeights = heights.map((heights) => Math.max(...heights));
  elements.forEach((innerEls, index) => {
    console.log(innerEls, 'innerEls');
    innerEls.forEach((element) => {
      element.style.minHeight = `${maxHeights[index]}px`;
    });
  });
};
