import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { getCookie } from 'cookies-next';
import styles from './HSForm.module.scss';
import { UTMS_COOKIE } from '@/pages/_app';

type HSFormProps = {
  formId: string;
  title?: string;
  type?: string;
  id?: string;
  className?: string;
};

function appendHiddenField(form: HTMLFormElement, key: string, value: string) {
  const input = document.createElement('input');
  input.setAttribute('type', 'hidden');
  input.setAttribute('name', key);
  input.setAttribute('class', 'hs-input');
  input.setAttribute('value', value as string);
  form.appendChild(input);
}

const HSForm: React.FC<HSFormProps> = ({ formId, title, type, id, className }) => {
  if (!formId) return null;


  return (
    <div className={`section ${className ? className : ''}`} id={id}>
      <div className="container">
        <div className={styles.formWrapper}>
          <div className="d-flex flex-column flex-lg-row">
            {title &&
              <div className={styles.title}>
                <h4>{title}</h4>
              </div>
            }


            <div className={styles.form}>
              <HubspotForm
                portalId="8942979"
                formId={formId}
                onFormReady={(form: any) => {
                  if (typeof window !== 'undefined') {
                    const utmCookies = getCookie(UTMS_COOKIE);
                    let utms;
                    if (utmCookies && typeof utmCookies === 'string') {
                      try {
                        utms = JSON.parse(utmCookies);
                      } catch (err) {
                        console.log(`error parsing utm cookies: ${err}`);
                      }
                    }
                    if (utms && Object.keys(utms).length) {
                      for (const [key, value] of Object.entries(utms)) {
                        const elValue = value as string; // utm params will always be strings
                        const existingElement = document.querySelector(
                          `input[name=${key}]`,
                        );
                        if (existingElement) {
                          (existingElement as HTMLInputElement).value = elValue;
                        } else {
                          appendHiddenField(form, key, elValue);
                        }

                        // ALL catcher
                        const existingElementAll = document.querySelector(
                          `input[name=${key}_all]`,
                        );
                        if (existingElementAll) {
                          (existingElementAll as HTMLInputElement).value =
                            elValue;
                        }
                      }
                    }
                  }
                }}
                loading={<div>Loading...</div>}

              />
            </div>

          </div>


          {/* <div className={styles.form} id={`hsf-${formId}`} /> */}
        </div>
      </div>
    </div>

  );
};
HSForm.displayName = 'HSForm';

export default HSForm;
