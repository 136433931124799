import { WordPressBlocksViewer } from '@faustwp/blocks';
import * as wpComponents from '@/componentsWP';
import { parseShortCode } from '@/helpers/shortcodes';
import get from 'lodash/get';
import { ReactNode } from 'react';

const BlockMix = ({
  blocksData = [],
  acfData,
  extra,
  additionalProps,
}: {
  blocksData: any;
  acfData?: any;
  extra?: {
    [key: string]: ReactNode;
  };
  additionalProps?: { [key: string]: any };
}) => {
  // ACF Components
  const afcComponents = {} as any;
  const components = acfData || [];

  // Banner or Slider
  let firstComponent = null;
  // First Component in mainContent
  let firstComponentMain = null;


  for (let i = 0; i < components.length; i++) {
    const c = components[i];

    // Component name
    const componentKey = c.__typename;
    // .split('_')
    // .pop(); // c.__typename.split('_').pop(); //Object.keys(c).find((ck) => ck.includes('component'));

    const component = c.__typename.split('_').pop();
    if (!(wpComponents as any)[component]) return null;
    const Component = (wpComponents as any)[component];
    let cKey = component;
    if (afcComponents[component]) {
      let count = 2;
      while (afcComponents[component + count]) {
        count++;
      }

      cKey = component + count;
    }

    afcComponents[cKey] = Component ? <Component {...c} /> : null;

    // First Component aways render
    if (i === 0 && (component === 'Banner' || component === 'Slider')) {
      firstComponent = <Component {...c} />;
    }
  }

  // GuttenBerg Blocks
  const Blocks = blocksData.map((block: any, idx: number) => {
    // ShortCode Block push ACF OR Custom Component



    if (block.__typename === 'CoreShortcode') {
      // clean
      const cleanShort = decodeURI(
        block?.renderedHtml.replace(/<\/?[^>]+(>|$)/g, ''),
      ).replace(/(\r\n|\n|\r)/gm, '');
      const widget = parseShortCode(cleanShort);
      const attributes = get(widget, 'attributes', {});
      const widgetId = get(attributes, 'id');
      const otherProps = get(additionalProps, `${widgetId}`, {});
      // ACF Component Found
      if (widgetId && afcComponents[widgetId]) {
        const toRender = <div key={widgetId}>{afcComponents[widgetId]}</div>;
        delete afcComponents[widgetId];
        return toRender;
      }

      // Custom Component Found wpComponents!!!!
      else if (widgetId && wpComponents[widgetId]) {
        const Component = wpComponents[widgetId] as any;
        return (
          <Component key={`block${idx}`} {...attributes} {...otherProps} />
        );
      }
      // Custom Component FROM query Found
      else if (widgetId && extra && extra[widgetId]) {
        // const Component = extra[widgetId] as any;
        return <div key={widgetId}>{extra[widgetId]}</div>;
      } else {
        return null;
      }
    }

    // if (idx === 0) {
    //   return (
    //     // <div className="container">
    //     <WordPressBlocksViewer blocks={[block]} key={`block${idx}`} />
    //     // </div>
    //   );
    // }

    return (
      // <div className="container">
      <WordPressBlocksViewer blocks={[block]} key={`block${idx}`} />
      // </div>
    );
  });

  const mainBlocks = [...Blocks];

  if (firstComponent) {
    return (
      <>

        <div>

          {firstComponent} {/* Banner|Slider */}

          <div className="firstWrapper">{Blocks[0]}</div>
        </div>
        <div className="mainContent">
          {mainBlocks.splice(1, mainBlocks.length)}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="mainContent">
        {mainBlocks}
      </div>
    </>
  );
};

export default BlockMix;
