import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    main: '#101828',
    secondary: '#34405A',
    copy: '#667085',
    tags: '#0D0E19',
  },
  weights: {
    Bold: {
      fontWeight: '900',
    },

    SemiBold: {
      fontWeight: '600',
    },

    SemiThin: {
      fontWeight: '400',
    },

    Thin: {
      fontWeight: '100',
    },
  },
  type2: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  TypeImgRound: {
    borderRadius: '5px',
  },
  circleImg: {
    borderRadius: '50%',
  },
  hoverSidebarName: {
    backgroundColor: '#898A92',
    color: '#0D0E19',
  },
};
