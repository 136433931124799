import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import styles from './BannerWork.module.scss';
import { Page_Builder_Sections_Banner } from 'generated-graphql';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import dynamic from 'next/dynamic';
import Head from 'next/head';
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const DynamicForm = dynamic(() => import('@/componentsWP/HSForm/HSForm'), {
  loading: () => <p>Loading form...</p>,
});

const BannerWork: React.FC<Page_Builder_Sections_Banner> = (props) => {
  const {
    copy,
    backgroundColor = '#ffffff',
    background,
    image,
    type = 'work',
    hubspotId,
    mask,
    video,
    videoUrl,
  } = props;



  const videoUrlToShow = video?.mediaItemUrl || videoUrl;

  const copyElement = copy && (
    <div className="container">
      <div className={styles.content}>
        <div className="row">
          <div className={`col-12 text-center`}>
            <div
              className={styles.copy}

            >
              <div dangerouslySetInnerHTML={{ __html: copy || '' }} />


              {image && <img src={image.sourceUrl || ''} style={{ width: '100%', marginTop: 20 }} />}

              {videoUrlToShow && <div style={{ height: 703, width: '100%', marginTop: 60, borderRadius: 30, overflow: 'hidden' }}>
                <ReactPlayer
                  // url="https://player.vimeo.com/video/848998101?h=26378de221&badge=0&autopause=0&player_id=0&app_id=58479&muted=1&autoplay=1&background=1&playsinline=1"
                  url={videoUrlToShow}
                  playing
                  width='100%'
                  height='702px'
                  muted
                  controls={false}

                  loop />
              </div>
              }
            </div>


          </div>

        </div>
      </div>
    </div>
  );



  return (
    <>
      <Head>
        <link rel="dns-prefetch" href="https://player.vimeo.com/" />
      </Head>
      <section className={styles.banner}>

        <div
          className={`${styles[`banner-${type}`]}`}
          style={{
            backgroundColor: backgroundColor || '#000000',
            backgroundImage: background?.sourceUrl
              ? `url("${background?.sourceUrl}")`
              : '',
          }}
        >
          {copyElement}


        </div>

      </section>
      <div className="heroEnd"></div>
    </>
  );
};


export default BannerWork;